import { UserId, ContactNumber, Address } from '../../modals/appointment';
import * as i0 from "@angular/core";
var PatientFactoryService = /** @class */ (function () {
    function PatientFactoryService() {
    }
    PatientFactoryService.prototype.createPatient = function (input, format, clinicId) {
        var patient = {
            appointmentId: '',
            action: '',
            appointmentDate: '',
            patientInfo: {
                name: '',
                remarks: '',
                gender: '',
                address: {
                    address: '',
                    country: '',
                    postalCode: ''
                },
                patientIdentifier: '',
                secondaryNumber: {
                    number: ''
                },
                contactNumber: {
                    number: ''
                },
                userId: {
                    idType: '',
                    number: ''
                }
            }
        };
        if (input && format) {
            this.setValues(patient, input, format);
        }
        return patient;
    };
    PatientFactoryService.prototype.setValues = function (appt, input, format) {
        var appointmentDatePara = localStorage.getItem('iappointmentDate');
        var apptValues;
        if (format === 'FORMGROUP') {
            apptValues = input.getRawValue();
        }
        appt.appointmentId = apptValues.appointmentId;
        appt.action = "RESCHEDULED";
        appt.appointmentDate = appointmentDatePara;
        appt.patientInfo.name = apptValues.name;
        appt.patientInfo.remarks = apptValues.remarks;
        appt.patientInfo.gender = apptValues.gender.toUpperCase();
        appt.patientInfo.patientIdentifier = apptValues.patientIdentifier;
        appt.patientInfo.secondaryNumber.number = apptValues.secondaryNumber;
        appt.patientInfo.address.postalCode = apptValues.postalCode;
        appt.patientInfo.address.country = "SINGAPORE";
        appt.patientInfo.address.address = apptValues.address;
        appt.patientInfo.contactNumber.number = apptValues.contactNumber;
        appt.patientInfo.userId.idType = apptValues.idType;
        if (apptValues.idType == "OTHER" && (apptValues.idNumber == null || apptValues.idNumber == "")) {
            appt.patientInfo.userId.number = apptValues.patientIdentifier;
        }
        else {
            appt.patientInfo.userId.number = apptValues.idNumber;
        }
    };
    PatientFactoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PatientFactoryService_Factory() { return new PatientFactoryService(); }, token: PatientFactoryService, providedIn: "root" });
    return PatientFactoryService;
}());
export { PatientFactoryService };
