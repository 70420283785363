import { doctorColorCode, DB_FULL_DATE_FORMAT_NO_SECOND, } from '../../constants/app.constants';
import { CustomisedApptInput, DoctorColorMap } from '../../modals/appointment';
import * as moment from 'moment';
import { UtilsService } from '../util/utils.service';
import * as i0 from "@angular/core";
import * as i1 from "../util/utils.service";
var doctorColorMap;
var AppointmentFactoryService = /** @class */ (function () {
    function AppointmentFactoryService(utilsService) {
        this.utilsService = utilsService;
    }
    AppointmentFactoryService.prototype.createAppointment = function (input, format, clinicId) {
        var appointment = {
            id: '',
            patientId: '',
            clinicId: '',
            preferredDoctor: null,
            referringDoctorId: '',
            referringClinicId: '',
            purposeOfVisit: null,
            remarks: '',
            startDate: '',
            reminderDate: null,
            visitDate: null,
            duration: 0,
            remark: '',
            status: 'PENDING',
            patientName: '',
            userId: {
                idType: '',
                number: '',
            },
            gender: '',
            dob: '',
            contactNumber: {
                number: '',
            },
            secondaryNumber: {
                number: '',
            },
            emailAddress: '',
            address: {
                address: '',
                country: '',
                postalCode: '',
            },
            patientSources: {},
            idType: '',
            name: '',
            idNumber: '',
            patientIdentifier: '',
            phoneType: '',
            phoneNumber: '',
            preRegistration: {
                purposeOfVisit: '',
                doctorMcr: '',
                clinicCode: '',
                type: '',
                remark: '',
                expectedArrivalTime: ''
            },
        };
        if (input && format) {
            this.setValues(appointment, input, format);
        }
        return appointment;
    };
    AppointmentFactoryService.prototype.setValues = function (appt, input, format) {
        var cliniccode = localStorage.getItem('clinicCode');
        var apptValues;
        var customisedInput;
        if (format === 'OBJECT') {
            apptValues = Object.assign(input);
            customisedInput = new CustomisedApptInput(apptValues.doctorId, apptValues.appointmentPurpose, apptValues.reminderDate, apptValues.startDate, apptValues.dob, apptValues.remark);
        }
        else if (format === 'FORMGROUP') {
            apptValues = input.getRawValue();
            customisedInput = new CustomisedApptInput(apptValues.preferredDoctor, apptValues.purposeOfVisit, apptValues.reminderDate, apptValues.visitDate, apptValues.dob, apptValues.remarks);
        }
        appt.id = apptValues.id;
        appt.patientId = apptValues.patientId;
        appt.clinicId = apptValues.clinicId;
        appt.preferredDoctor = customisedInput.preferredDoctor;
        appt.referringDoctorId = apptValues.referringDoctorId;
        appt.referringClinicId = apptValues.referringClinicId;
        appt.purposeOfVisit = customisedInput.purposeOfVisit;
        appt.remarks = customisedInput.remarks;
        appt.reminderDate = customisedInput.reminderDate;
        appt.visitDate = customisedInput.visitDate;
        appt.duration = apptValues.duration;
        appt.status = apptValues.status;
        appt.patientName = apptValues.patientName;
        appt.gender = apptValues.gender;
        appt.dob = apptValues.dob;
        appt.contactNumber = apptValues.contactNumber;
        appt.emailAddress = apptValues.emailAddress;
        appt.address = apptValues.address;
        appt.remarks = customisedInput.remarks;
        appt.patientSources = apptValues.patientSources;
        // Payment Reference will be provided only via online bookings
        // hence will be populated directly in appointment list and not
        // created newly from calendar
        if (apptValues.paymentReference) {
            appt.paymentReference = apptValues.paymentReference;
        }
        appt.idType = apptValues.idType;
        appt.name = apptValues.name;
        appt.idNumber = apptValues.idNumber;
        appt.patientIdentifier = apptValues.patientIdentifier;
        appt.phoneType = apptValues.phoneType;
        appt.phoneNumber = apptValues.phoneNumber;
        appt.contactNumber = apptValues.contactNumber;
        appt.userId.idType = apptValues.idType;
        appt.userId.number = apptValues.idNumber;
        appt.preRegistration.purposeOfVisit = "NPHS";
        appt.preRegistration.type = "APPOINTMENT";
        appt.preRegistration.doctorMcr = "EXT_APP_DUMMY_MCR";
        appt.preRegistration.remark = apptValues.remarks;
        appt.preRegistration.clinicCode = cliniccode;
        appt.preRegistration.expectedArrivalTime = moment(customisedInput.visitDate).format(DB_FULL_DATE_FORMAT_NO_SECOND);
    };
    AppointmentFactoryService.prototype.decreaseTimeByInterval = function (startDateTime, minuteInterval) {
        if (startDateTime === void 0) { startDateTime = null; }
        var dateTime = startDateTime ? startDateTime : new Date();
        dateTime.setMinutes(dateTime.getMinutes() - minuteInterval);
        return dateTime;
    };
    AppointmentFactoryService.prototype.calculateDuration = function (startDateTime, endDateTime) {
        var difference = endDateTime.getTime() - startDateTime.getTime(); // This will give difference in milliseconds
        var resultInMinutes = Math.round(difference / 60000);
        return resultInMinutes;
    };
    AppointmentFactoryService.prototype.initDoctorColorMap = function () {
        doctorColorMap = new Array();
        doctorColorCode.forEach(function (color) {
            var map = new DoctorColorMap('', color);
            doctorColorMap.push(map);
        });
    };
    AppointmentFactoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppointmentFactoryService_Factory() { return new AppointmentFactoryService(i0.ɵɵinject(i1.UtilsService)); }, token: AppointmentFactoryService, providedIn: "root" });
    return AppointmentFactoryService;
}());
export { AppointmentFactoryService };
