import { Injector } from "@angular/core";
import { AuthServiceService } from "../auth/auth-service.service";
import * as i0 from "@angular/core";
var TokenInterceptorService = /** @class */ (function () {
    function TokenInterceptorService(injector) {
        this.injector = injector;
    }
    TokenInterceptorService.prototype.intercept = function (req, next) {
        var authService = this.injector.get(AuthServiceService);
        var tokenizedReq = req.clone({
            setHeaders: {
                Authorization: "Bearer " + authService.getToken(),
                'Content-Type': 'application/json',
                'X-Tenant': "HMC_555b5f65e8041214de5f",
            },
        });
        return next.handle(tokenizedReq);
    };
    TokenInterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TokenInterceptorService_Factory() { return new TokenInterceptorService(i0.ɵɵinject(i0.INJECTOR)); }, token: TokenInterceptorService, providedIn: "root" });
    return TokenInterceptorService;
}());
export { TokenInterceptorService };
