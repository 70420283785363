import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalService, BsModalRef, } from 'ngx-bootstrap/modal'
import { SharedService } from '../../services/shared/shared.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { DISPLAY_DATE_FORMAT, DB_VISIT_DATE_FORMAT, DB_FULL_DATE_FORMAT, DB_FULL_DATE_TIMEZONE_Z, DISPLAY_DATE_TIME_NO_SECONDS_FORMAT } from '../../constants/app.constants';
import { ApiAppointmentService } from '../../services/appointment/api-appointment.service';
import { JsonToCsvService } from '../../services/jsonToCsv/json-to-csv.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/ngx-bootstrap-datepicker';

@Component({
  selector: 'app-print-appointment',
  templateUrl: './print-appointment.component.html',
  styleUrls: ['./print-appointment.component.scss']
})
export class PrintAppointmentComponent implements OnInit {
  title: string;
  maxDate: Date;
  minDate: Date;
  bsConfig: Partial<BsDatepickerConfig>;
  appointmentExportForm: FormGroup;
  isSaving = false;
  public allAppointments = [];
  public allAppointmentsExport = [];
  constructor(
    private cd: ChangeDetectorRef,
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private apiAppointmentService: ApiAppointmentService,
    private jsonToCsv: JsonToCsvService,
  ) {
    this.minDate = new Date();
    this.maxDate = moment(new Date()).add(6, 'month').toDate();
    this.appointmentExportForm = this.createAppointmentFormGroup();
  }
  ngOnInit() {
    this.configureDatePicker();
    this.setInitialAppointmentData();
  }
  setInitialAppointmentData() {
    let startDate = moment(this.minDate, DISPLAY_DATE_FORMAT)
    .subtract(1, 'month')
    .format(
      DISPLAY_DATE_FORMAT
    );
    let endDate = moment(this.minDate, DISPLAY_DATE_FORMAT).format(
      DISPLAY_DATE_FORMAT
    );
    this.appointmentExportForm.patchValue({
      startDate: startDate,
      endDate: endDate
    });

  }
  configureDatePicker() {
    this.bsConfig = Object.assign({}, {
      containerClass: 'theme-blue',
      dateInputFormat: 'DD/MM/YYYY',
      isAnimated: true,
      returnFocusToInput: true

    });
  }
  onchangeStartDate(value: Date): void {
    this.cd.detectChanges();
    if (value == null || value == undefined) {

    } else {
      this.appointmentExportForm.patchValue({
        startDate: moment(value).format(
          DISPLAY_DATE_FORMAT
        )
      });

    }

  }
  onchangeEndDate(value: Date): void {
    this.cd.detectChanges();
    if (value == null || value == undefined) {

    } else {
      this.appointmentExportForm.patchValue({
        endDate: moment(value).format(
          DISPLAY_DATE_FORMAT
        )
      });

    }

  }
  createAppointmentFormGroup(): FormGroup {
    return this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
    });

  }
  getAppointmentsPrint() {
    this.isSaving = true;
    let clinicId = localStorage.getItem("clinicId");
    let startDate;
    startDate = moment(this.appointmentExportForm.get('startDate').value).format(
      DB_VISIT_DATE_FORMAT
    );
    if (moment(startDate, "DD-MM-YYYY").isValid()) {

    } else {
      startDate = this.appointmentExportForm.get('startDate').value + "T00:00:00";

    }
    let endDate;
    endDate = moment(this.appointmentExportForm.get('endDate').value).format(
      DB_VISIT_DATE_FORMAT
    );
    if (moment(endDate, "DD-MM-YYYY").isValid()) {

    } else {
      endDate = this.appointmentExportForm.get('endDate').value + "T00:00:00";

    }

    this.apiAppointmentService.getAppointments(clinicId, startDate, endDate).subscribe(
      res => {
        this.allAppointments = res.payload;
        let tempAPpointmentArray = [];
        this.allAppointments.forEach(appointment => {
          let newdate = moment(appointment.startDate, DB_FULL_DATE_FORMAT).format(
            DB_FULL_DATE_TIMEZONE_Z
          );
          appointment.unix = ((new Date(newdate)).getTime() / 1000);
          appointment.startDate = moment(appointment.startDate, DB_FULL_DATE_FORMAT).format(
            DISPLAY_DATE_TIME_NO_SECONDS_FORMAT
          );
          tempAPpointmentArray.push(appointment)
        });
        this.allAppointments = tempAPpointmentArray;
        this.allAppointments.sort(function (a, b) { return a.unix - b.unix });
        if (this.allAppointments.length > 0) {
          this.exportToCSV();
        } else {
          this.toastr.warning("No record found!  Please change date range and export.")
          this.isSaving = false;
        }

      },
      err => {
        this.isSaving = false;
        if (err.error.message == "Access Denied") {
          this.router.navigate(['/login'])
        } else {
          this.toastr.error(err.error.message ? err.error.message : "")
        }
      }
    );
  };

  exportToCSV() {
    this.allAppointmentsExport = [];
    this.allAppointments.forEach(row => {

      var name = row.patientName.replace(/,/g, ' ')
      var exportRow = {
        "Name": name,
        "Appointment Date": moment(row.startDate, DB_FULL_DATE_FORMAT).format(
          DISPLAY_DATE_TIME_NO_SECONDS_FORMAT
        ),
        "Contact Number": row.contactNumber.number,
        "ID Number": row.userId.number,
        "Serial Hi": row.patientIdentifier,
        "Last Modified By": row.lastModifiedBy,
        "Created By": row.createUsername
      };
      this.allAppointmentsExport.push(exportRow);
    });
    this.jsonToCsv.downloadFile(this.allAppointmentsExport, 'All Appointments');
    this.isSaving = false;
    this.toastr.success("Exported " + this.allAppointmentsExport.length + " Records!")
  };

  closeModalBox() {
    this.bsModalRef.hide();
  }


}
