import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { AuthServiceService } from '../../services/auth/auth-service.service';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login-with-two-fa',
  templateUrl: './login-with-two-fa.component.html',
  styleUrls: ['./login-with-two-fa.component.scss']
})
export class LoginWithTwoFaComponent implements OnInit {

  public form!: FormGroup;
  
  public mobile: string = '';
  private token: string = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthServiceService,
    private toaster: ToastrService
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(result => {
      this.token = result.token;
      this.mobile = result.mobile;

      this.form = new FormGroup({
        twoFactorPin: new FormControl(null, [Validators.required]),
        token: new FormControl(this.token)
      });
    });
  }

  public onSubmit(): void {
    this.authService.loginWithTwoFA(this.form.value).subscribe({
      next: (result: any) => {
        if(result.message === 'SUCCESS') {
          localStorage.setItem('access_token', result.payload);

          this.authService.getUserDetails();
        }
      },
      error: error => {
        this.toaster.error(error.error.message);
      }
    });
  }
}