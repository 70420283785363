/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./errors.component";
var styles_ErrorsComponent = [];
var RenderType_ErrorsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ErrorsComponent, data: {} });
export { RenderType_ErrorsComponent as RenderType_ErrorsComponent };
function View_ErrorsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "li", [["style", "color: red; list-style-type: none;"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_ErrorsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ul", [["class", "p-0 m-0"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ErrorsComponent_2)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errors(); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ErrorsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "small", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ErrorsComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showErrors(); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ErrorsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "errors", [], null, null, null, View_ErrorsComponent_0, RenderType_ErrorsComponent)), i0.ɵdid(1, 49152, null, 0, i2.ErrorsComponent, [], null, null)], null, null); }
var ErrorsComponentNgFactory = i0.ɵccf("errors", i2.ErrorsComponent, View_ErrorsComponent_Host_0, { control: "control" }, {}, []);
export { ErrorsComponentNgFactory as ErrorsComponentNgFactory };
