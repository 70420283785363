import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';

import { DefaultLayoutComponent } from './containers';
import { LoginComponent } from './views/login/login.component';
import { LoginWithTwoFaComponent } from './views/login-with-two-fa/login-with-two-fa.component';
import { ExternalAppointmentNewComponent } from './views/external-appointment-new/external-appointment-new.component';
import { AppointmentAuthGuard } from './guards/appointment.auth.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'two-factor/:token/:mobile',
    component: LoginWithTwoFaComponent,
    data: {
      title: '2FA Authentication'
    }
  },
  {
    path: 'create-appointment',
    component: ExternalAppointmentNewComponent,
    data: {
      title: 'External Appointment Page'
    },
    canActivate: [AppointmentAuthGuard]
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
      }
    ]
  },
  { path: '**', component: LoginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
