import { FormGroup, Validators } from '@angular/forms';
import { PatientFactoryService } from './patient-factory.service';
import { PatientRequest } from '../../requests/patientRequest';
import { AppointmentValidators } from '../../validators/AppointmentValidators';
import * as i0 from "@angular/core";
import * as i1 from "./patient-factory.service";
var PatientFormService = /** @class */ (function () {
    function PatientFormService(patientFactory) {
        this.patientFactory = patientFactory;
    }
    PatientFormService.prototype.setMandatoryFields = function (patientForm) {
        var patientIdentifier = patientForm.get('patientIdentifier');
        var contactNumber = patientForm.get('contactNumber');
        var secondaryNumber = patientForm.get('secondaryNumber');
        // this.setRequired(name);
        this.setRequired(patientIdentifier);
        this.setRequired(contactNumber);
        this.setRequired(patientIdentifier);
        patientIdentifier.setValidators(AppointmentValidators.validateSerialHi());
        contactNumber.setValidators(AppointmentValidators.validateValidateMobile());
        // secondaryNumber.setValidators(AppointmentValidators.validateValidateMobile());
    };
    PatientFormService.prototype.setRequired = function (form) {
        form.setValidators(Validators.required);
    };
    PatientFormService.prototype.setAppointmentForApi = function (input, isReferral) {
        var patient = input;
        if (input instanceof FormGroup) {
            patient = this.patientFactory.createPatient(input, 'FORMGROUP');
        }
        //
        var patientRequest = new PatientRequest(patient);
        return patientRequest;
    };
    PatientFormService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PatientFormService_Factory() { return new PatientFormService(i0.ɵɵinject(i1.PatientFactoryService)); }, token: PatientFormService, providedIn: "root" });
    return PatientFormService;
}());
export { PatientFormService };
