import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalService, BsModalRef, } from 'ngx-bootstrap/modal'
import { ToastrService } from 'ngx-toastr';
import { AuthServiceService } from '../../services/auth/auth-service.service';
import { Router } from '@angular/router';
import { AppointmentFormService } from '../../services/appointment/appointment-form.service';


@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  peditForm: FormGroup;
  title:string;
  constructor(
    private router: Router,
    public bsModalRef: BsModalRef,
    private authSevice: AuthServiceService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private appointmentsFormService:AppointmentFormService,
  ) { 
    this.peditForm = this.createAppointmentFormGroup();
  }

  ngOnInit() {
    this.setMandatoryFields();
  }
  setMandatoryFields() {
    this.appointmentsFormService.setMandatoryPassword(
      this.peditForm,
    );
  }

  createAppointmentFormGroup(): FormGroup {
    return this.fb.group({
      oldPassword: [''],
      newPassword: [''],
      confirmNewPassword: ['', Validators.required],
    });

  }

  closeModalBox() {
    this.bsModalRef.hide();
  }

  onClickLogin() {
    let tokenval=localStorage.getItem('access_token');
    let oldPasswordVal= this.peditForm.get('oldPassword').value;
    let newPasswordVal= this.peditForm.get('newPassword').value;
    let confirmNewPasswordVal= this.peditForm.get('confirmNewPassword').value;

if(newPasswordVal==confirmNewPasswordVal){
  this.authSevice.updatePassword(oldPasswordVal,newPasswordVal).subscribe(
    resp => {
      let { payload } = resp;
      this.toastr.success("PASSWORD UPDATED SUCCESSFULLY");
      this.bsModalRef.hide();
      localStorage.clear();
      this.router.navigate(['/login'])
    },
    err => {
      if(err.error.message=="Access Denied"){
        this.router.navigate(['/login'])
      }else{
        this.toastr.error(err.error.message ? err.error.message : "")
      }
    }
  );
}else{

  this.toastr.error("PASSWORD AND CONFIRM PASSWORD SHOULD BE SAME")
}
 
  

  }
}
