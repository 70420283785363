<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModalBox()">
        <span arial-hidden="true">&times;</span>
    </button>

</div>
<div class="modal-body" [formGroup]="appointmentExportForm">
    <label> Start Date</label>

    <input type="text" class="form-control" placeholder="DD/MM/YYYY" class="form-control datepicker" [maxDate] = "maxDate"
        [bsConfig]="bsConfig" (bsValueChange)="onchangeStartDate($event)" bsDatepicker formControlName="startDate">

    <label class="mt-2"> End Date</label>

    <input type="text" class="form-control" placeholder="DD/MM/YYYY" class="form-control datepicker" [maxDate] = "maxDate"
        [bsConfig]="bsConfig" (bsValueChange)="onchangeEndDate($event)" bsDatepicker formControlName="endDate">


</div>


<div class="modal-footer">
    <button type="button" class="btn btn-warning pull-left" aria-label="Close" (click)="closeModalBox()">
        Cancel
    </button>
    <button *ngIf="!isSaving" class="btn btn-primary pull-right" (click)="getAppointmentsPrint()"
        [disabled]="!appointmentExportForm.valid ">
        <span *ngIf="!isSaving">Export</span>
    </button>

    <button *ngIf="isSaving" class="btn btn-primary pull-right">
        <span *ngIf="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span *ngIf="isSaving" class="sr-only"> Loading...</span>
    </button>
</div>