// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
};
// export const baseUrl=`https://api2.healthwaymedical.com.sg/cms-dua`;
// export const loginUrl=`https://api.healthwaymedical.com.sg`;

export const baseUrl = `https://cmsapi.healthwaymedical.info`;
export const loginUrl = `https://cmsapi.healthwaymedical.info`;

// export const baseUrl = `https://digi.healthwaymedical.com.sg/cms-dua`;
// export const loginUrl = `https://digi.healthwaymedical.com.sg`;

// export const baseUrl=`https://mgmtnav.healthwaymedical.info`;

//  export const baseUrl=`https://api2.healthwaymedical.com.sg/cms-dua`;
