


  <div class="modal-header">
    <h4 class="modal-title pull-left"> {{ title }}</h4>
  
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModalBox()"><span aria-hidden="true">&times;</span></button>
</div>



  <div [formGroup]="appointmentForm" class="container-fluid h-100">
    <div class="row p-2 pb-3">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
        
                    <div class="row">
                        <div class="col-md-4 pb-0 ">
                            <label>Select Clinic</label>
                            <ng-select class="p-0 form-control form-control-sm custom-select" [items]="clinics"   [hideSelected]="true" 
                            bindLabel='name' bindValue='id'
                           
                          

                             (change)="onChangeClinic($event)" >
                        </ng-select>
                           <!-- placeholder="Select Clinic"  -->
                        </div>
                        <div class="col-md-4 pb-0">

                            
                            <label>Appointment Date</label>
                            

                            <span *ngIf="!isCalendarDisabled"    >
                                
                                <input type="text"
                                class="form-control"
                                placeholder="DD/MM/YYYY"
                                class="form-control form-control-sm datepicker"
                                [bsConfig]="bsConfig"
                                (input)="onchangeManual($event.target.value)"
                                (bsValueChange)="onchangeAppointmentDate($event)"
                                bsDatepicker
                                formControlName="appointmentDate"
                                >

                            </span>
                            <br>
                            <span *ngIf="isCalendarDisabled"  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
           



                         
                          
                            <errors [control]="appointmentForm.get('appointmentDate')"></errors>
                        </div>
                        <div class="col-md-4 pb-0">
                            <label>Appointment Time</label>
                         


                        <span *ngIf="!isTimeDisabled"    >
                                
                            <ng-select class="p-0 form-control form-control-sm custom-select"
                            [items]="availableTimesDropDownList"  
                            onkeypress="return false;" 
                            (change)="onChangeTime($event)" 
                            formControlName="appointmentTime"
                            >
                       </ng-select>

                        </span>
                        <br>
                        <span *ngIf="isTimeDisabled"  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>

                            <errors [control]="appointmentForm.get('appointmentTime')"></errors>
                        </div>
                    </div>
                
                    <div class="row" >
                        <div class="col-md-4 pb-0">
                            <label>Name(As per NRIC)</label>
                         <input class="form-control form-control-sm"
                         placeholder="Enter Name" formControlName="name" />

                            <errors [control]="appointmentForm.get('name')"></errors>
                        </div>
                        <div class="col-md-4 pb-0">
                            <label>Identification Type</label>
                            <ng-select class="p-0 form-control form-control-sm custom-select"
                            [items]="idTypes" bindLabel="label" bindValue="value"   [hideSelected]="true" 
                            formControlName="idType"
                            >
                       </ng-select>
                
                       <!-- placeholder="Select Id Type" -->
               


                        </div>
                        <div class="col-md-4 pb-0">
                            <label>Identification Number</label>
                            <input class="form-control form-control-sm" formControlName="idNumber"   placeholder="NRIC" />
                        </div>
                    </div>
          

            


                    <div class="row">
                  
                        <div class="col-md-4 pb-0">
                            <label>SerialHi</label>
                            <input class="form-control form-control-sm" placeholder="Enter Serial Hi" formControlName="patientIdentifier" />
                            <errors [control]="appointmentForm.get('patientIdentifier')"></errors>
                        </div>
                        
                        <div class="col-md-4 pb-0">
                            <label>Select Mobile/Home</label>
                                   <ng-select class="p-0 form-control  form-control-sm custom-select  custom-select-extra-medium-width" [items]="phoneType"
                     bindLabel="value" bindValue="value"
                        value="value"  [clearable]=false>
                    </ng-select>
                    <!-- placeholder="Select Mobile/Home"  -->
                        </div>
                        <div class="col-md-4 pb-0">
                            <label>Contact Number </label>
                            <input class="form-control form-control-sm"  formControlName="contactNumber"
                            placeholder="e.g. +65 91234567" />
                            <errors [control]="appointmentForm.get('contactNumber')"></errors>
                           
                        </div>
                    </div>
                       
                    <div class="row">
                   
                        <!-- <div class="col-md-4 pb-0">
                            <label>Next Appointment <span class="compulsory">*</span></label>
                            <input type="text" class="form-control form-control-sm" [value]="nextAppointment" readonly>
                        </div> -->
                        <div class="col-md-8 pb-0">
                            <label>Remarks</label>
                            <textarea class="form-control form-control-sm" formControlName="remarks" ></textarea>
                            <errors [control]="appointmentForm.get('remarks')"></errors>
                        </div>
                    </div>
               
                </div>
            
                <div class="modal-footer">
               
                    
                    <button class="btn btn-warning pull-left"  (click)="closeModalBoxCancel()" >Cancel</button>
                                        <!-- <button class="btn btn-large btn-brand-primary" (click)="onBtnSaveClicked()" [disabled]="!appointmentFormGroup.valid || !apptIsAvailable || isSaving || (hasDoubleAppointments && !userConfirmedWarning) "> -->
                    <button class="btn btn-info pull-right" *ngIf="!isSaving"  [disabled]="!appointmentForm.valid " (click)="createNewAppointment()"   >
                        <span *ngIf="!isSaving"    >Save Appointment</span>
                        <span *ngIf="isSaving"  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span *ngIf="isSaving" class="sr-only"> Loading...</span>
                    </button>

                    <button class="btn btn-info pull-right" *ngIf="isSaving"  [disabled]="!appointmentForm.valid "   >
                        <span *ngIf="!isSaving"    >Save Appointment</span>
                        <span *ngIf="isSaving"  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span *ngIf="isSaving" class="sr-only"> Loading...</span>
                    </button>
               
            </div>
            </div>  
         

        </div>
   
    </div>
</div>