import {
  APPOINTMENT,
  DOCTOR_BLOCKED_TIME,
  DOCTOR_LEAVE,
  CLINIC_HOLIDAY,
  noPreferredDoctorColorCode,
  locumColorCode,
  doctorColorCode,
  DB_FULL_DATE_FORMAT_NO_SECOND,
  DISPLAY_DATE_FORMAT,
} from '../../constants/app.constants';


import { CustomisedApptInput, DoctorColorMap } from '../../modals/appointment';


import { Injectable } from '@angular/core';
import {
  Appointment,
  UserId,
  ContactNumber,
  Address,
  PreRegistration
} from '../../modals/appointment';
import * as moment from 'moment';
import { UtilsService } from '../util/utils.service';
import { Patient, PatientInfo } from '../../modals/patient';
import { ApiPatientService } from './api-patient.service';


@Injectable({
  providedIn: 'root'
})
export class PatientFactoryService {

  constructor() { }


  createPatient(
    input?,
    format?,
    clinicId?
  ) {
    let patient: Patient = {
      appointmentId:'',
      action: '',
      appointmentDate: '',
      patientInfo:<PatientInfo>{
        name: '',
        remarks:'',
        gender:'',
        address:<Address>{
          address: '',
          country: '',
          postalCode: ''
        },
        patientIdentifier: '',
        secondaryNumber: <ContactNumber>{
          number:''
        },
        contactNumber: <ContactNumber>{
          number:''
        },
        userId:<UserId>{
          idType: '',
          number: ''
        }
      }
    };

    if (input && format) {
      this.setValues(patient, input, format);
    }
    return patient;
  }
  setValues(appt: Patient, input, format) {

    let appointmentDatePara= localStorage.getItem('iappointmentDate');
     let apptValues;
  if (format === 'FORMGROUP') {
       apptValues = <Patient>input.getRawValue();
     }
     appt.appointmentId = apptValues.appointmentId;
     appt.action = "RESCHEDULED";
     appt.appointmentDate = appointmentDatePara;
     appt.patientInfo.name=apptValues.name;
     appt.patientInfo.remarks=apptValues.remarks;
     appt.patientInfo.gender=apptValues.gender.toUpperCase();
     appt.patientInfo.patientIdentifier=apptValues.patientIdentifier;
     appt.patientInfo.secondaryNumber.number=apptValues.secondaryNumber;
     appt.patientInfo.address.postalCode=apptValues.postalCode;
     appt.patientInfo.address.country="SINGAPORE";
     appt.patientInfo.address.address=apptValues.address;
     appt.patientInfo.contactNumber.number=apptValues.contactNumber;
     appt.patientInfo.userId.idType=apptValues.idType;
 
     if(apptValues.idType=="OTHER"&& (apptValues.idNumber==null || apptValues.idNumber=="")){
   
      appt.patientInfo.userId.number=apptValues.patientIdentifier;
     }else{
    
      appt.patientInfo.userId.number=apptValues.idNumber;
     }
     
    
   }
}

