import { HttpClient } from '@angular/common/http';
import { baseUrl } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ApiPatientService = /** @class */ (function () {
    function ApiPatientService(http) {
        this.http = http;
        this.API_URL = baseUrl;
    }
    ApiPatientService.prototype.updatePatientData = function (patientData) {
        if (patientData.patientInfo.gender) {
            if (patientData.patientInfo.gender.toLowerCase() == "male" || patientData.patientInfo.gender.toLowerCase() == "female") {
            }
        }
        else {
            delete patientData.patientInfo.gender;
        }
        return this.http.post(this.API_URL + "/cms-dua/patient/pre-registration/update", JSON.stringify(patientData));
    };
    ApiPatientService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiPatientService_Factory() { return new ApiPatientService(i0.ɵɵinject(i1.HttpClient)); }, token: ApiPatientService, providedIn: "root" });
    return ApiPatientService;
}());
export { ApiPatientService };
