var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    suppressScrollX: true
};
// Import containers
import { DefaultLayoutComponent } from './containers';
var APP_CONTAINERS = [
    DefaultLayoutComponent
];
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
var ɵ0 = adapterFactory;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
