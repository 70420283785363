import { Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { DefaultLayoutComponent } from './containers';
import { LoginComponent } from './views/login/login.component';
import { LoginWithTwoFaComponent } from './views/login-with-two-fa/login-with-two-fa.component';
import { ExternalAppointmentNewComponent } from './views/external-appointment-new/external-appointment-new.component';
import { AppointmentAuthGuard } from './guards/appointment.auth.guard';
var ɵ0 = {
    title: 'Login Page'
}, ɵ1 = {
    title: '2FA Authentication'
}, ɵ2 = {
    title: 'External Appointment Page'
}, ɵ3 = {
    title: 'Home'
}, ɵ4 = function () { return import("./views/dashboard/dashboard.module.ngfactory").then(function (m) { return m.DashboardModuleNgFactory; }); };
export var routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
    {
        path: 'login',
        component: LoginComponent,
        data: ɵ0
    },
    {
        path: 'two-factor/:token/:mobile',
        component: LoginWithTwoFaComponent,
        data: ɵ1
    },
    {
        path: 'create-appointment',
        component: ExternalAppointmentNewComponent,
        data: ɵ2,
        canActivate: [AppointmentAuthGuard]
    },
    {
        path: '',
        component: DefaultLayoutComponent,
        data: ɵ3,
        children: [
            {
                path: 'dashboard',
                loadChildren: ɵ4,
                canActivate: [AuthGuard]
            }
        ]
    },
    { path: '**', component: LoginComponent },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
