import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Clinic } from '../../modals/clinic';
import { CLINICS_ARRAY, DISPLAY_DATE_FORMAT, DISPLAY_DATE_TIME_NO_SECONDS_FORMAT, IDTYPES_ARRAY, PHONETYPE_ARRAY } from '../../constants/app.constants';
import * as moment from 'moment';
import { ApiAppointmentService } from '../../services/appointment/api-appointment.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DateTimeSlots, DoctorAvailableSlots } from '../../modals/appointment';
import { AppointmentFormService } from '../../services/appointment/appointment-form.service';
import { IdTypes } from '../../modals/idTypes';
import { SharedService } from '../../services/shared/shared.service';
import { AppointmentValidators } from '../../validators/AppointmentValidators';

@Component({
  selector: 'app-external-appointment-new',
  templateUrl: './external-appointment-new.component.html',
  styleUrls: ['./external-appointment-new.component.scss']
})
export class ExternalAppointmentNewComponent implements OnInit {
  public appointmentForm: FormGroup;
  clinics: Array<Clinic>;
  serialHi: string = '';
  interviewDate: string = '';
  bsConfig: Partial<BsDatepickerConfig>;
  minDate: Date;
  maxDate: Date;
  availableTimesDropDownList: Array<string>;
  isTimeDisabled = false;
  availableTimes: Array<DoctorAvailableSlots>;
  public tempAPpointmentArray = [];
  idTypes: Array<IdTypes>;
  phoneType = [];
  isClickedOnce: boolean;
  constructor(
    private fb: FormBuilder,
    private apiAppointmentService: ApiAppointmentService,
    private toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appointmentsFormService: AppointmentFormService,
    private sharedService: SharedService,
  ) {
  }

  ngOnInit() {
    this.populateData();
    this.configureDatePicker();
  }

  populateData() {
    this.activatedRoute.queryParams.subscribe(result => {
      this.serialHi = result.serialHi;
      this.interviewDate = result.interviewDate;
      const twoDayDate = moment(new Date(), DISPLAY_DATE_FORMAT).add(2, 'days');
      if (this.interviewDate) {
        const twoWeekDate = moment(this.interviewDate, DISPLAY_DATE_FORMAT).add(2, 'weeks');
        const isDiffTwoWeeks = twoWeekDate.isSameOrBefore(moment());
        this.minDate = isDiffTwoWeeks ? twoDayDate.toDate() : twoWeekDate.toDate();
      } else {
        this.minDate = twoDayDate.toDate();
      }
      this.maxDate = this.interviewDate ? moment(result.interviewDate, DISPLAY_DATE_FORMAT)
        .add(2, 'months')
        .toDate() : moment(new Date(), DISPLAY_DATE_FORMAT).add(2, 'months').toDate();
      this.appointmentForm = this.createAppointmentFormGroup();
    });
    this.clinics = CLINICS_ARRAY;
    this.idTypes = IDTYPES_ARRAY;
    this.phoneType = PHONETYPE_ARRAY;
    //this.setMandatoryFields();  
    this.subscribeValueChanges()  
  }

  subscribeValueChanges(){
    this.appointmentForm.valueChanges
    .subscribe(res=>{
      this.isClickedOnce = false;
    })
  }

  createAppointmentFormGroup(): FormGroup {
    return this.fb.group({
      clinicId: ["", Validators.required],
      appointmentDate: ["", [Validators.required, this.validateAppointmentDate.bind(this)]],
      patientIdentifier: [this.serialHi, AppointmentValidators.validateSerialHi()],
      appointmentTime: ["",Validators.required],
      name: ["", Validators.required],
      idType: ["", Validators.required],
      idNumber: ["", Validators.required],
      contactNumber: ["", [Validators.required, AppointmentValidators.validateValidateMobile()]],
      remarks: [""],
    });
  }

  configureDatePicker() {
    this.bsConfig = Object.assign(
      {},
      {
        containerClass: "theme-blue",
        dateInputFormat: "DD/MM/YYYY",
        isAnimated: true,
        minDate: this.minDate,
        maxDate: this.maxDate,
        returnFocusToInput: true,
      }
    );
  }

  onChangeClinic(selectedClinic) {
    this.availableTimesDropDownList = [];
    this.isTimeDisabled = false;
    this.appointmentForm.patchValue({
      startTime: "",
      appointmentTime: "",
    });
    localStorage.setItem("clinicId", selectedClinic.id);
    localStorage.setItem("clinicCode", selectedClinic.clinicCode);
    this.getTimeSlots(false);
  }

  getTimeSlots(dateSelect: boolean) {
    if (dateSelect) {
      this.isTimeDisabled = true;
      this.availableTimesDropDownList = [];
      this.availableTimes = new Array<DoctorAvailableSlots>();
    }
    let clinicCode = localStorage.getItem("clinicCode");
    var AppointmentTodayDate = new Date();
    var appointmentDate = dateSelect ? localStorage.getItem("appointmentDate") : moment(AppointmentTodayDate).format(DISPLAY_DATE_FORMAT);
    this.apiAppointmentService
      .getTimeSlotsPublic(clinicCode, appointmentDate)
      .subscribe(
        (res) => {
          if (dateSelect) {
            res.payload[0].timeSlot.forEach((SlotObj) => {
              if (SlotObj.calendarDayYear.date == appointmentDate) {
                if (!this.tempAPpointmentArray.includes(SlotObj.start)) {
                  this.availableTimesDropDownList.push(SlotObj.start);
                }
              }
            });
            var unique = this.availableTimesDropDownList.filter(function (
              elem,
              index,
              self
            ) {
              return index === self.indexOf(elem);
            });
            this.availableTimesDropDownList = unique;
            this.availableTimesDropDownList.sort();
            this.isTimeDisabled = false;
            if (this.availableTimesDropDownList.length > 0) {
            } else {
              this.toastr.warning("No slots available for selected date!");
            }
          } else {
            this.isTimeDisabled = false;
            this.configureDatePicker();
          }
        },
        (err) => {
          if (err.error.message == "Access Denied") {
            this.router.navigate(["/login"]);
          } else {
            this.toastr.error(err.error.message ? err.error.message : "");
          }
        }
      );
  }

  onchangeManual(value: Date): void {
    this.isTimeDisabled = false;
    this.availableTimesDropDownList = [];
    this.appointmentForm.patchValue({
      startTime: "",
      appointmentTime: "",
    });
  }

  onchangeAppointmentDate(value: Date): void {
    this.isTimeDisabled = false;
    this.availableTimesDropDownList = [];
    this.appointmentForm.patchValue({
      startTime: "",
      appointmentTime: "",
    });

    if (value == null || value == undefined) {
    } else {
      if (value.getTime() <= this.maxDate.getTime() && value.getTime() >= this.minDate.getTime()) {
        localStorage.setItem(
          "appointmentDate",
          moment(value).format(DISPLAY_DATE_FORMAT)
        );
        this.getTimeSlots(true);
      } else {
      }
    }
  }

  setMandatoryFields() {
    this.appointmentsFormService.setMandatoryFields(this.appointmentForm);
  }

  onChangeTime(selectedTime) {
    localStorage.setItem("appointmentTime", selectedTime);
  }

  public onCancel(): void {
    this.appointmentForm.reset();
    this.appointmentForm.get('patientIdentifier').patchValue(this.serialHi);
  }

  public onSubmit(): void {
    if (!this.appointmentForm.invalid){
      this.isClickedOnce = true;
      let selectedTimeSlot = localStorage.getItem("appointmentTime");
      this.checkTimeSlots(selectedTimeSlot);
    } else {
      this.appointmentForm.markAllAsTouched();
      this.appointmentForm.updateValueAndValidity();
    }
  }

  checkTimeSlots(selectedTime) {
    var slotFlag = 0;
    this.availableTimes = new Array<DoctorAvailableSlots>();
    let clinicId = localStorage.getItem("clinicId");
    let clinicCode = localStorage.getItem("clinicCode");
    let appointmentDate = localStorage.getItem("appointmentDate");
    if (
      appointmentDate != null &&
      appointmentDate != undefined &&
      appointmentDate != ""
    ) {
      this.apiAppointmentService
        .getTimeSlotsPublic(clinicCode, appointmentDate)
        .subscribe(
          (res) => {
            res.payload
              .filter((slot) => slot.timeSlot.length > 0)
              .forEach((slot) => {
                let timeSlots = new Array<string>();
                slot.timeSlot.forEach((time) => {
                  timeSlots.push(time.start);
                });
                const dateTime: DateTimeSlots = new DateTimeSlots(
                  slot.timeSlot[0].calendarDayYear.date,
                  timeSlots
                );
                const arrayDateTimeSlots = new Array<DateTimeSlots>();
                arrayDateTimeSlots.push(dateTime);
                const doctorAvailableSlots = new DoctorAvailableSlots(
                  slot.doctorId,
                  arrayDateTimeSlots
                );
                this.availableTimes.push(doctorAvailableSlots);
              });

            if (this.availableTimes.length > 0) {
              this.availableTimesDropDownList =
                this.availableTimes[0].dateTimeSlots[0].availableTimeSlots;
            }
            this.availableTimesDropDownList.forEach((time) => {
              if (time == selectedTime) {
                slotFlag = 1;
              }
            });
            if (slotFlag == 1) {
              this.createNewAppointmentExecute();
            } else {
              this.toastr.warning("Time slot is not available at this time!");
            }
          },
          (err) => {
            if (err.error.message == "Access Denied") {
              this.router.navigate(["/login"]);
            } else {
              this.toastr.error(err.error.message ? err.error.message : "");
            }
          }
        );
    }
  }

  setFormGroupForSaveApi() {
    this.appointmentForm.patchValue({
      startTime: "",
    });
    return this.appointmentsFormService.setAppointmentForApi(
      this.appointmentForm,
      false
    );
  }

  createNewAppointmentExecute() {
    let idValue = this.appointmentForm.get("idNumber").value;
    let idTYpeValue = this.appointmentForm.get("idType").value;
    let IdValCheck = this.sharedService.validateNRIC(idValue);
    if (
      !IdValCheck &&
      (idTYpeValue == "NRIC_PINK" ||
        idTYpeValue == "NRIC_BLUE" ||
        idTYpeValue == "FIN")
    ) {
      this.toastr.error("INVALID ID CARD NUMBER");
    } else {
      const appointment = this.setFormGroupForSaveApi();
      appointment.preRegistration.expectedArrivalTime =
        moment(this.appointmentForm.get("appointmentDate").value).format(
          DISPLAY_DATE_FORMAT
        ) +
        "T" +
        localStorage.getItem("appointmentTime");
      this.apiAppointmentService.createAppointment(appointment).subscribe(
        (res) => {
          this.toastr.success("APPOINTMENT CREATED SUCCESSFULLY");
          this.onCancel();
        },
        (err) => {
          if (err.error.message == "Access Denied") {
            this.router.navigate(["/login"]);
          } else if (err.error.message.includes("patientIdentifier")) {
            this.toastr.error("This Patient ID/SerialHi is already in use and cannot be duplicated.");
          } else {
            this.toastr.error(err.error.message ? err.error.message : "");
          }
        }
      );
    }
  }

validateAppointmentDate(control: FormGroup){
  const appointmentDate = control.value;
  if (appointmentDate){
    const minDate = moment(this.minDate, DISPLAY_DATE_FORMAT).format(DISPLAY_DATE_FORMAT);
    const maxDate = moment(this.maxDate, DISPLAY_DATE_FORMAT).format(DISPLAY_DATE_FORMAT);
    const currentDate = moment(control.value, DISPLAY_DATE_FORMAT).format(DISPLAY_DATE_FORMAT);
    
    if (moment(currentDate, DISPLAY_DATE_FORMAT).isBefore(moment(minDate, DISPLAY_DATE_FORMAT)) || moment(currentDate, DISPLAY_DATE_FORMAT).isAfter(moment(maxDate, DISPLAY_DATE_FORMAT))) {
        return {
          vaildEmail: { value: appointmentDate, message: `Please select date between ${minDate} and ${maxDate}` },
        };
      } else null 
    }
  }
}

