import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { UserLogin } from '../../modals/user';
import { HttpResponseBody } from '../../modals/HttpResponseBody';

import { baseUrl, loginUrl } from '../../../environments/environment';
import { TwoFA, TwoFAResponse } from '../../modals/TwoFA';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {
  private API_URL;
  private API_LOGIN_URL;

  private twoFASub = new Subject<boolean>();

  constructor(
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService
    ) {
    this.API_URL = baseUrl;
    this.API_LOGIN_URL = loginUrl;
  }

  public setTwoFAStatus(status: boolean): void {
    this.twoFASub.next(status);
  }

  public getTwoFAStatus(): Observable<boolean> {
    return this.twoFASub.asObservable();
  }

  login(user: UserLogin) {
    return this.http.post(`${this.API_LOGIN_URL}/aacore/login`, JSON.stringify(user), {
      observe: 'response',
    });
  }

  getUser(): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(`${this.API_LOGIN_URL}/aacore/user`, '');
  }

  updatePassword(oldPassword: string, newPassword: string): Observable<HttpResponseBody> {
    let oldPasswordValue = oldPassword;
    let newPasswordValue = newPassword;
    return this.http.post<HttpResponseBody>(`${this.API_LOGIN_URL}/aacore/user/change/password/${oldPasswordValue}/${newPasswordValue}`, {});
  }

  loggedIn() {
    return !!localStorage.getItem('access_token');
  }

  public loginWithTwoFA(data: TwoFA): Observable<TwoFAResponse> {
    return this.http.post<TwoFAResponse>(`${this.API_LOGIN_URL}/aacore/user/2fa`, data);
  }

  public getUserDetails(): void {
    this.getUser().subscribe(
      (resp) => {
        let { payload } = resp;
        localStorage.setItem('firstName', payload.firstName);
        this.router.navigate(['/dashboard']);
      },
      (err) => {
        this.toastr.error(err.error.message ? err.error.message : '');
      }
    );
  }

  getToken() {
    return localStorage.getItem("access_token");
  }
}