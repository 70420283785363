<div class="modal-header">
    <h4 class="modal-title pull-left"> {{ title }}</h4>
  
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModalBox()"><span aria-hidden="true">&times;</span></button>
</div>

<div [formGroup]="patientForm" class="container-fluid h-100">
    <div class="row p-2 pb-3">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
        
              
                
                    <div class="row" >
                        <div class="col-md-4 pb-0">
                            <label>Name(As per NRIC)</label>
                         <input class="form-control form-control-sm"
                         placeholder="Enter Name" formControlName="name" />

                            <errors [control]="patientForm.get('name')"></errors>
                        </div>
                        <div class="col-md-4 pb-0">
                            <label>Identification Type</label>
                            <ng-select class="p-0 form-control form-control-sm custom-select"
                            [items]="idTypes" bindLabel="label" bindValue="value"   [hideSelected]="true" 
                            placeholder="Select Id Type" formControlName="idType"
                            value="value"  [clearable]=false
                            >
                       </ng-select>

                        </div>
                        <div class="col-md-4 pb-0">
                            <label>Identification Number</label>
                            <input class="form-control form-control-sm" formControlName="idNumber"   placeholder="NRIC" />
                        </div>
                    </div>


            

                    <div class="row">
                  
                        <div class="col-md-4 pb-0">
                            <label>SerialHi</label>
                            <input class="form-control form-control-sm" placeholder="Enter Serial Hi" formControlName="patientIdentifier" />
                            <errors [control]="patientForm.get('patientIdentifier')"></errors>
                        </div>
                        
                        <div class="col-md-4 pb-0">
                            <label>Select Mobile/Home</label>
                                   <ng-select class="p-0 form-control  form-control-sm custom-select  custom-select-extra-medium-width" [items]="phoneType"
                     bindLabel="value" bindValue="value" placeholder="Select Mobile/Home"  formControlName="phoneType"
                        value="value"  [clearable]=false>
                    </ng-select>
                        </div>
                        <div class="col-md-4 pb-0">
                            <label>Contact Number </label>
                            <input class="form-control form-control-sm"  formControlName="contactNumber"
                            placeholder="e.g. +65 91234567" />
                            <errors [control]="patientForm.get('contactNumber')"></errors>
                           
                        </div>
                    </div>



                  


                    <div class="row">
                        <div class="col-md-4 pb-0">
                            <label>Contact Number 2</label>
                         <input class="form-control form-control-sm"
                         placeholder="Enter Contact Number" formControlName="secondaryNumber" />
                         <!-- <errors [control]="patientForm.get('secondaryNumber')"></errors> -->
                        </div>
                        <div class="col-md-4 pb-0">
                            <label>Select Gender</label>
                                   <ng-select class="p-0 form-control  form-control-sm custom-select  custom-select-extra-medium-width" [items]="genderType"
                     bindLabel="value" bindValue="value" placeholder="Select gender"  formControlName="gender"
                        value="value"  [clearable]=false>
                    </ng-select>
                        </div>
                        <div class="col-md-4 pb-0">
                            <label>Postal Code</label>
                            <input class="form-control form-control-sm" (keypress)="numberOnly($event)" formControlName="postalCode" />
                        </div>

                        <div class="col-md-8 pb-0">
                            <label>Address</label>
                            <textarea class="form-control form-control-sm" formControlName="address" ></textarea>
                        </div>
                        <!-- <div class="col-md-8 pb-0">
                            <label>Remarks</label>
                            <textarea class="form-control form-control-sm" formControlName="remarks" ></textarea>
                        </div> -->
                      
                    </div>
                    
                    
                   
               
                </div>
            
                <div class="modal-footer">
               
                    
                    <button class="btn btn-warning pull-left"  (click)="closeModalBox()" >Cancel</button>
                                        <!-- <button class="btn btn-large btn-brand-primary" (click)="onBtnSaveClicked()" [disabled]="!appointmentFormGroup.valid || !apptIsAvailable || isSaving || (hasDoubleAppointments && !userConfirmedWarning) "> -->
                    <button  class="btn btn-info pull-right"  (click)="updatePatientInfo()" [disabled]="!patientForm.valid"    >
                        <span *ngIf="!isSaving"  >Update</span>
                        <span *ngIf="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span *ngIf="isSaving" class="sr-only"> Loading...</span>
                    </button>



               
            </div>
            </div>  
         

        </div>
   
    </div>
</div>
