<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModalBox()">
        <span arial-hidden="true">&times;</span>
    </button>

</div>

<div class="modal-body" [formGroup]="appointmentEditForm">
    <label> Appointment Date</label>


    <span *ngIf="!isCalendarDisabled">
        <input type="text" class="form-control" placeholder="DD/MM/YYYY" class="form-control datepicker"
            [bsConfig]="bsConfig" (bsValueChange)="onchangeAppointmentDate($event)" bsDatepicker
            formControlName="appointmentDate">

    </span>
    <div *ngIf="isCalendarDisabled">
        <br>
    </div>
  
    <span *ngIf="isCalendarDisabled" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
<div *ngIf="isCalendarDisabled">
    <br>
</div>
    



    <label> Appointment TIme</label>

    <span *ngIf="!isTimeDisabled">
        <ng-select class="p-0 form-control " [items]="availableTimesDropDownList" [hideSelected]="true"
        (change)="onTimeSelect($event)" onkeypress="return false;" formControlName="appointmentTime">
    </ng-select>

    </span>
    <div *ngIf="isTimeDisabled">
        <br>
    </div>
  
    <span *ngIf="isTimeDisabled" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
<div *ngIf="isTimeDisabled">
    <br>
</div>



   

</div>

<div class="modal-footer">
    <button type="button" class="btn btn-warning pull-left" aria-label="Close" (click)="closeModalBox()">
        Cancel
    </button>
    <button class="btn btn-primary pull-right" [disabled]="!appointmentEditForm.valid ">
        <span (click)="onSaveClick()" *ngIf="!isSaving">Update</span>
        <span *ngIf="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span *ngIf="isSaving" class="sr-only"> Loading...</span>
    </button>
</div>