import { HttpClient } from '@angular/common/http';
import { baseUrl } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ApiAppointmentService = /** @class */ (function () {
    function ApiAppointmentService(http) {
        this.http = http;
        this.API_URL = baseUrl;
    }
    ApiAppointmentService.prototype.getTimeSlots = function (clinicCode, appointmentDate) {
        return this.http.post(this.API_URL + "/cms-dua/patient/pre-registration/tele-consult/available/time-slot/" + clinicCode + "/" + appointmentDate, ["EXT_APP_DUMMY_MCR"]);
    };
    ApiAppointmentService.prototype.getTimeSlotsPublic = function (clinicCode, appointmentDate) {
        var uniqueId;
        return this.http.post(this.API_URL + "/cms-dua/public-appointments/35de1f66/available/time-slot/" + clinicCode + "/" + appointmentDate, ["EXT_APP_DUMMY_MCR"]);
    };
    ApiAppointmentService.prototype.getAppointments = function (clinicId, startDate, endDate) {
        var obj = {
            "clinicId": clinicId != null ? clinicId : localStorage.getItem("clinicId"),
            "startDate": startDate,
            "endDate": endDate
        };
        return this.http.post(this.API_URL + "/cms-dua/patient/pre-registration/list/calendar", JSON.stringify(obj));
    };
    ApiAppointmentService.prototype.updateAppointments = function (appointmentId, newDate) {
        var obj = {
            "appointmentId": appointmentId,
            "action": "RESCHEDULED",
            "appointmentDate": newDate
        };
        return this.http.post(this.API_URL + "/cms-dua/patient/pre-registration/update", JSON.stringify(obj));
    };
    ApiAppointmentService.prototype.create = function (appointment) {
        var obj = {
            "number": appointment.contactNumber
        };
        appointment.contactNumber = obj;
        appointment.duration = 30;
        appointment.preRegistration.doctorMcr = "EXT_APP_DUMMY_MCR";
        appointment.preRegistration.remark = appointment.remark;
        if (appointment.userId.idType == null || appointment.userId.idType == "" || appointment.userId.number == null || appointment.userId.number == "") {
            appointment.userId.idType = "OTHER";
            appointment.userId.number = appointment.patientIdentifier;
        }
        return this.http.post(this.API_URL + "/cms-dua/patient/pre-registration/create", JSON.stringify(appointment));
    };
    ApiAppointmentService.prototype.createAppointment = function (appointment) {
        var obj = {
            "number": appointment.contactNumber
        };
        appointment.contactNumber = obj;
        appointment.duration = 30;
        appointment.preRegistration.doctorMcr = "EXT_APP_DUMMY_MCR";
        appointment.preRegistration.remark = appointment.remark;
        if (appointment.userId.idType == null || appointment.userId.idType == "" || appointment.userId.number == null || appointment.userId.number == "") {
            appointment.userId.idType = "OTHER";
            appointment.userId.number = appointment.patientIdentifier;
        }
        return this.http.post(this.API_URL + "/cms-dua/public-appointments/35de1f66/pre-register/NPHS", JSON.stringify(appointment));
    };
    ApiAppointmentService.prototype.updatePassword = function (oldPassword, newPassword) {
        var oldPasswordValue = oldPassword;
        var newPasswordValue = newPassword;
        return this.http.post(this.API_URL + "/aacore/user/change/password/" + oldPasswordValue + "/" + newPasswordValue, {});
    };
    ApiAppointmentService.prototype.remove = function (appointmentId) {
        var obj = {
            "appointmentId": appointmentId,
            "action": "CANCELED"
        };
        return this.http.post(this.API_URL + "/cms-dua/patient/pre-registration/update", JSON.stringify(obj));
    };
    ApiAppointmentService.prototype.updatePassword1 = function (oldPassword, newPassword) {
        var oldPasswordValue = oldPassword;
        var newPasswordValue = newPassword;
        return this.http.post(this.API_URL + "/aacore/user/change/password/" + oldPasswordValue + "/" + newPasswordValue, {});
    };
    ApiAppointmentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiAppointmentService_Factory() { return new ApiAppointmentService(i0.ɵɵinject(i1.HttpClient)); }, token: ApiAppointmentService, providedIn: "root" });
    return ApiAppointmentService;
}());
export { ApiAppointmentService };
