import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { Validators, FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthServiceService } from "../../services/auth/auth-service.service";
import { UserLogin } from "../../modals/user";
import { ToastrService } from "ngx-toastr";
var LoginComponent = /** @class */ (function () {
    function LoginComponent(router, authSevice, fb, toastr) {
        this.router = router;
        this.authSevice = authSevice;
        this.fb = fb;
        this.toastr = toastr;
        this.loginFormGroup = this.createLoginFormGroup();
    }
    LoginComponent.prototype.ngOnInit = function () { };
    LoginComponent.prototype.createLoginFormGroup = function () {
        return this.fb.group({
            userName: ['', Validators.required],
            password: ['', Validators.required],
        });
    };
    LoginComponent.prototype.onClickLogin = function () {
        var _this = this;
        localStorage.clear();
        this.username = this.loginFormGroup.get('userName').value;
        this.password = this.loginFormGroup.get('password').value;
        var user = new UserLogin(this.username, this.password);
        this.authSevice.login(user).subscribe({
            next: function (result) {
                var token = result.headers.get('Authorization')
                    ? result.headers.get('Authorization').substring(7)
                    : result.headers.get('authorization').substring(7);
                localStorage.setItem('access_token', token);
                var decoded_token = JSON.parse(atob(token.split('.')[1]));
                if (decoded_token['require-2fa'] !== undefined &&
                    decoded_token['require-2fa'] !== null &&
                    decoded_token['require-2fa'] === true) {
                    _this.router.navigate(["/two-factor/" + token + "/" + result.body.mobile]);
                }
                else {
                    _this.authSevice.getUserDetails();
                }
            },
            error: function (error) {
                _this.toastr.error(error.error.message ? error.error.message : '');
            }
        });
    };
    LoginComponent.prototype.delay = function (ms) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(function () { return resolve(); }, ms); }).then(function () { return console.log('fired'); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return LoginComponent;
}());
export { LoginComponent };
