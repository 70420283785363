import { Component, OnInit, Directive, HostListener } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import * as moment from "moment";
import {
  DISPLAY_DATE_FORMAT,
  CLINICS_ARRAY,
  DISPLAY_TIME_NO_SECONDS_24_FORMAT,
  APPOINTMENT,
  IDTYPES_ARRAY,
  PHONETYPE_ARRAY,
  EXTERNAL_USER_ROLE,
  APPOINTMENT_SUPERVISOR_ROLE,
  INVENTORY_DATE_FORMAT,
  NPHS_SUPERVISOR_ROLE,
  DB_VISIT_DATE_FORMAT,
  DISPLAY_DATE_TIME_NO_SECONDS_FORMAT,
  DB_FULL_DATE_FORMAT,
} from "../../constants/app.constants";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { AppointmentFormService } from "../../services/appointment/appointment-form.service";
import { Clinic } from "../../modals/clinic";
import { IdTypes } from "../../modals/idTypes";
import {
  DoctorAvailableSlots,
  DateTimeSlots,
  ContactNumber,
  UserId,
  PreRegistration,
} from "../../modals/appointment";
import { UtilsService } from "../../services/util/utils.service";
import { SharedService } from "../../services/shared/shared.service";
import { ToastrService } from "ngx-toastr";
import { ApiAppointmentService } from "../../services/appointment/api-appointment.service";
import { AuthServiceService } from "../../services/auth/auth-service.service";
import { Route } from "@angular/compiler/src/core";
import { Router } from "@angular/router";
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
  addMinutes,
} from "date-fns";
@Component({
  selector: "app-appointment-new",
  templateUrl: "./appointment-new.component.html",
  styleUrls: ["./appointment-new.component.scss"],
})
export class AppointmentNewComponent implements OnInit {
  title: string;
  bsConfig: Partial<BsDatepickerConfig>;
  minDate: Date;
  maxDate: Date;
  appointmentForm: FormGroup;
  isSaving = false;
  clinics: Array<Clinic>;
  idTypes: Array<IdTypes>;
  phoneType = [];
  disabledDates = [];
  isCalendarDisabled = false;
  isTimeDisabled = false;
  nextAppointment = "";
  availableTimes: Array<DoctorAvailableSlots>;
  availableTimesDropDownList: Array<string>;
  clinicId: string;
  name: string;
  disabledDays = [0, 1, 2, 3, 4, 5, 6];
  patientIdentifier: string;
  contactNumber: ContactNumber;
  userId: UserId;
  public allAppointments = [];
  public tempAPpointmentArray = [];
  preRegistration: PreRegistration;
  constructor(
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private appointmentsFormService: AppointmentFormService,
    private apiAppointmentService: ApiAppointmentService,
    private utilsService: UtilsService,
    private sharedService: SharedService,
    private toastr: ToastrService,
    private authService: AuthServiceService,
    private router: Router
  ) {
    this.minDate = new Date();
    this.maxDate = new Date();
    this.getUserRoles();
    this.appointmentForm = this.createAppointmentFormGroup();
  }

  getUserRoles() {
    this.authService.getUser().subscribe(
      (resp) => {
        let { payload } = resp;
        if (payload.roles.includes(EXTERNAL_USER_ROLE)) {
          this.minDate.setDate(this.minDate.getDate() + 14);
          this.maxDate.setDate(this.maxDate.getDate() + 60);
        } else if (payload.roles.includes(NPHS_SUPERVISOR_ROLE)) {
          this.minDate.setDate(this.minDate.getDate());
          this.maxDate.setDate(this.maxDate.getDate() + 300);
        } else {
          // for internal user no validation
          this.minDate.setDate(this.minDate.getDate());
          this.maxDate.setDate(this.maxDate.getDate() + 300);
        }
      },
      (err) => {
        if (err.error.message == "Access Denied") {
          this.router.navigate(["/login"]);
        } else {
          this.toastr.error(err.error.message ? err.error.message : "");
        }
      }
    );
  }
  ngOnInit() {
    this.configureDatePicker();
    this.populateData();
  }
  private populateData() {
    this.clinics = CLINICS_ARRAY;
    localStorage.setItem("clinicId", CLINICS_ARRAY[0].id);
    let appointmentDate = moment(new Date()).format(DISPLAY_DATE_FORMAT);
    localStorage.setItem("appointmentDate", appointmentDate);
    this.idTypes = IDTYPES_ARRAY;
    this.phoneType = PHONETYPE_ARRAY;
    this.setMandatoryFields();
  }

  getMonthlyTimeSlots() {
    let clinicCode = localStorage.getItem("clinicCode");
    var AppointmentTodayDate = new Date();
    var finalMonthTodayDate =
      moment(AppointmentTodayDate).format(DISPLAY_DATE_FORMAT);
    this.apiAppointmentService
      .getTimeSlots(clinicCode, finalMonthTodayDate)
      .subscribe(
        (res) => {
          console.log("here are all slots", JSON.stringify(res.payload));
          res.payload
            .filter((slot) => slot.timeSlot.length > 0)
            .forEach((slot) => {
              var monthsDatesArray = [];
              slot.timeSlot.forEach((time) => {
                monthsDatesArray.push(time.calendarDayYear.date);
              });
              for (let i = 0; i < 61; i++) {
                var monthDate = addDays(new Date(), i);
                var finalMonthDate =
                  moment(monthDate).format(DISPLAY_DATE_FORMAT);
                if (monthsDatesArray.includes(finalMonthDate)) {
                } else {
                  var newdate = finalMonthDate.split("-").reverse().join("-");
                  var enteryDate = new Date(newdate);
                  this.disabledDates.push(enteryDate);
                }
              }
            });
          this.disabledDays = [];
          this.isCalendarDisabled = false;
          this.isTimeDisabled = false;
          this.configureDatePicker();
        },
        (err) => {
          if (err.error.message == "Access Denied") {
            this.router.navigate(["/login"]);
          } else {
            this.toastr.error(err.error.message ? err.error.message : "");
          }
        }
      );
  }

  configureDatePicker() {
    this.bsConfig = Object.assign(
      {},
      {
        containerClass: "theme-blue",
        dateInputFormat: "DD/MM/YYYY",
        isAnimated: true,
        minDate: this.minDate,
        maxDate: this.maxDate,
        datesDisabled: this.disabledDates,
        daysDisabled: this.disabledDays,
        // placement="top"
        returnFocusToInput: true,
      }
    );
  }
  onChangeClinic(selectedClinic) {
    this.disabledDays = [0, 1, 2, 3, 4, 5, 6];
    this.availableTimesDropDownList = [];
    this.disabledDates = [];
    this.isCalendarDisabled = true;
    this.isTimeDisabled = false;
    this.appointmentForm.patchValue({
      startTime: "",
      appointmentTime: "",
    });
    localStorage.setItem("clinicId", selectedClinic.id);
    localStorage.setItem("clinicCode", selectedClinic.clinicCode);
    this.getMonthlyTimeSlots();
    //  this.getTimeSlots();
  }
  onChangeTime(selectedTime) {

    localStorage.setItem("appointmentTime", selectedTime);
  }
  onSearchChange(searchValue: string): void {
    console.log("date change fired here", searchValue)
    console.log(searchValue);
    this.appointmentForm.patchValue({
      startTime: "",
      appointmentTime: "",
    });
  }

  onchangeManual(value: Date): void {
    console.log("date change fired here in manual", value)
    this.isTimeDisabled = false;
    this.availableTimesDropDownList = [];
    this.appointmentForm.patchValue({
      startTime: "",
      appointmentTime: "",
    });

  }
  onchangeAppointmentDate(value: Date): void {
    console.log("date change fired here", value)
    this.isTimeDisabled = false;
    this.availableTimesDropDownList = [];
    this.appointmentForm.patchValue({
      startTime: "",
      appointmentTime: "",
    });


    if (value == null || value == undefined) {
    } else {

      if (value.getTime() <= this.maxDate.getTime() && value.getTime() >= this.minDate.getTime()) {
        localStorage.setItem(
          "appointmentDate",
          moment(value).format(DISPLAY_DATE_FORMAT)
        );
        this.getAppointmentsAll();
      } else {

      }

    }
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  createAppointmentFormGroup(): FormGroup {
    return this.fb.group({
      appointmentDate: [""],
      patientIdentifier: [""],
      appointmentTime: [""],
      name: [""],
      idType: [""],
      idNumber: [""],
      contactNumber: [""],
      remarks: [""],
    });
  }
  setMandatoryFields() {
    this.appointmentsFormService.setMandatoryFields(this.appointmentForm);
  }
  closeModalBox() {
    let datepicked = moment(
      this.appointmentForm.get("appointmentDate").value
    ).format(DISPLAY_DATE_FORMAT);
    this.bsModalRef.hide();
  }
  closeModalBoxCancel() {
    this.bsModalRef.hide();
  }

  getAppointmentsAll() {
    let clinicId = localStorage.getItem("clinicId");
    let startDate = moment(this.minDate).format(DB_VISIT_DATE_FORMAT);

    let endDate = moment(this.maxDate).format(DB_VISIT_DATE_FORMAT);
    this.apiAppointmentService
      .getAppointments(clinicId, startDate, endDate)
      .subscribe(
        (res) => {
          this.tempAPpointmentArray = [];
          this.allAppointments = res.payload;

          let tempeventsArray = [];

          let appointmentDate = localStorage.getItem("appointmentDate");
          this.allAppointments.forEach((appointment) => {
            appointment.startDate = moment(
              appointment.startDate,
              DB_FULL_DATE_FORMAT
            ).format(DISPLAY_DATE_TIME_NO_SECONDS_FORMAT);

            let timeOnly = moment(
              appointment.startDate,
              DISPLAY_DATE_TIME_NO_SECONDS_FORMAT
            ).format(DISPLAY_TIME_NO_SECONDS_24_FORMAT);

            let dateOnly = moment(
              appointment.startDate,
              DISPLAY_DATE_FORMAT
            ).format(DISPLAY_DATE_FORMAT);

            if (appointmentDate == dateOnly) {
              this.tempAPpointmentArray.push(timeOnly);
            }
          });
          this.getTimeSlots();
        },
        (err) => {
          if (err.error.message == "Access Denied") {
            this.router.navigate(["/login"]);
          } else {
            this.toastr.error(err.error.message ? err.error.message : "");
          }
        }
      );
  }
  getTimeSlots() {
    this.isTimeDisabled = true;
    this.availableTimesDropDownList = [];
    this.availableTimes = new Array<DoctorAvailableSlots>();
    let clinicId = localStorage.getItem("clinicId");
    let clinicCode = localStorage.getItem("clinicCode");
    let appointmentDate = localStorage.getItem("appointmentDate");
    if (
      appointmentDate != null &&
      appointmentDate != undefined &&
      appointmentDate != ""
    ) {
      this.apiAppointmentService
        .getTimeSlots(clinicCode, appointmentDate)
        .subscribe(
          (res) => {
            res.payload[0].timeSlot.forEach((SlotObj) => {
              if (SlotObj.calendarDayYear.date == appointmentDate) {
                if (!this.tempAPpointmentArray.includes(SlotObj.start)) {
                  this.availableTimesDropDownList.push(SlotObj.start);
                }
              }
            });
            var unique = this.availableTimesDropDownList.filter(function (
              elem,
              index,
              self
            ) {
              return index === self.indexOf(elem);
            });
            this.availableTimesDropDownList = unique;
            this.availableTimesDropDownList.sort();
            this.isTimeDisabled = false;
            if (this.availableTimesDropDownList.length > 0) {
            } else {
              this.toastr.warning("No slots available for selected date!");
            }
          },
          (err) => {
            if (err.status != 200) {
              this.toastr.error("Could not fetch slots!");
              return;
            }
            if (err!.error!.message == "Access Denied") {
              this.router.navigate(["/login"]);
            } else {
              this.toastr.error(err.error.message ? err.error.message : "");
            }
          }
        );
    }
  }

  getVisitTime() {
    const date: Date = this.appointmentForm.get("appointmentDate")
      .value as Date;
    const time = this.utilsService.convertDateToTime(date);
    if (this.availableTimesDropDownList) {
      const availableTimeSlot = this.availableTimesDropDownList.find(
        (timeOption) => {
          return moment(
            timeOption,
            DISPLAY_TIME_NO_SECONDS_24_FORMAT
          ).isSameOrAfter(moment(time, DISPLAY_TIME_NO_SECONDS_24_FORMAT));
        }
      );
      if (availableTimeSlot === undefined) {
        return this.utilsService.convertDateToTime(date);
      } else {
        return availableTimeSlot;
      }
    }
  }
  mergeArray(array1, array2) {
    let resultArray = [];
    let arr = array1.concat(array2);
    let len = arr.length;
    let assoc = {};
    while (len--) {
      let item = arr[len];
      if (!assoc[item]) {
        resultArray.unshift(item);
        assoc[item] = true;
      }
    }
    return resultArray.sort();
  }

  insertAppointment() {
    return this.appointmentsFormService.setAppointmentForApi(
      this.appointmentForm,
      false
    );
  }
  setFormGroupForSaveApi() {
    this.appointmentForm.patchValue({
      startTime: "",
    });
    return this.appointmentsFormService.setAppointmentForApi(
      this.appointmentForm,
      false
    );
  }
  createNewAppointmentExecute() {
    this.isSaving = true;
    let idValue = this.appointmentForm.get("idNumber").value;
    let idTYpeValue = this.appointmentForm.get("idType").value;
    let IdValCheck = this.sharedService.validateNRIC(idValue);
    if (
      !IdValCheck &&
      (idTYpeValue == "NRIC_PINK" ||
        idTYpeValue == "NRIC_BLUE" ||
        idTYpeValue == "FIN")
    ) {
      this.toastr.error("INVALID ID CARD NUMBER");
    } else {
      const appointment = this.setFormGroupForSaveApi();
      appointment.preRegistration.expectedArrivalTime =
        moment(this.appointmentForm.get("appointmentDate").value).format(
          DISPLAY_DATE_FORMAT
        ) +
        "T" +
        localStorage.getItem("appointmentTime");
      this.apiAppointmentService.create(appointment).subscribe(
        (res) => {
          this.toastr.success("APPOINTMENT CREATED SUCCESSFULLY");
          this.bsModalRef.hide();
          this.sharedService.sendClickEvent();
        },
        (err) => {
          if (err.error.message == "Access Denied") {
            this.router.navigate(["/login"]);
          } else {
            this.toastr.error(err.error.message ? err.error.message : "");
          }
          this.isSaving = false;
        }
      );
    }
  }
  async delay(ms: number) {
    await new Promise<void>((resolve) => setTimeout(() => resolve(), ms)).then(
      () => console.log("fired")
    );
  }

  createNewAppointment() {
    this.isSaving = true;
    let selectedTimeSlot = localStorage.getItem("appointmentTime");
    this.checkTimeSlots(selectedTimeSlot);
  }

  checkTimeSlots(selectedTime) {
    var slotFlag = 0;
    this.availableTimes = new Array<DoctorAvailableSlots>();
    let clinicId = localStorage.getItem("clinicId");
    let clinicCode = localStorage.getItem("clinicCode");
    let appointmentDate = localStorage.getItem("appointmentDate");
    if (
      appointmentDate != null &&
      appointmentDate != undefined &&
      appointmentDate != ""
    ) {
      this.apiAppointmentService
        .getTimeSlots(clinicCode, appointmentDate)
        .subscribe(
          (res) => {
            res.payload
              .filter((slot) => slot.timeSlot.length > 0)
              .forEach((slot) => {
                let timeSlots = new Array<string>();
                slot.timeSlot.forEach((time) => {
                  timeSlots.push(time.start);
                });
                const dateTime: DateTimeSlots = new DateTimeSlots(
                  slot.timeSlot[0].calendarDayYear.date,
                  timeSlots
                );
                const arrayDateTimeSlots = new Array<DateTimeSlots>();
                arrayDateTimeSlots.push(dateTime);
                const doctorAvailableSlots = new DoctorAvailableSlots(
                  slot.doctorId,
                  arrayDateTimeSlots
                );
                this.availableTimes.push(doctorAvailableSlots);
              });

            if (this.availableTimes.length > 0) {
              this.availableTimesDropDownList =
                this.availableTimes[0].dateTimeSlots[0].availableTimeSlots;
            }
            this.availableTimesDropDownList.forEach((time) => {
              if (time == selectedTime) {
                slotFlag = 1;
              }
            });
            if (slotFlag == 1) {
              this.createNewAppointmentExecute();
            } else {
              this.toastr.warning("Time slot is not available at this time!");
            }
          },
          (err) => {
            if (err.error.message == "Access Denied") {
              this.router.navigate(["/login"]);
            } else {
              this.toastr.error(err.error.message ? err.error.message : "");
            }
          }
        );
    }
  }
}
