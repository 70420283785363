import { Injectable } from '@angular/core';
import { FormGroup, AbstractControl, Validators } from '@angular/forms';
import { PatientFactoryService } from './patient-factory.service';
import { PatientRequest } from '../../requests/patientRequest';
import { AppointmentValidators } from '../../validators/AppointmentValidators';

@Injectable({
  providedIn: 'root'
})
export class PatientFormService {

  constructor(
    private patientFactory: PatientFactoryService,
  ) { }

  setMandatoryFields(patientForm: FormGroup) {
  
    const patientIdentifier = patientForm.get('patientIdentifier');
    const contactNumber = patientForm.get('contactNumber');
    const secondaryNumber = patientForm.get('secondaryNumber');


    // this.setRequired(name);
   this.setRequired(patientIdentifier);
   this.setRequired(contactNumber);
   this.setRequired(patientIdentifier);
    patientIdentifier.setValidators(AppointmentValidators.validateSerialHi());
    contactNumber.setValidators(AppointmentValidators.validateValidateMobile());

    // secondaryNumber.setValidators(AppointmentValidators.validateValidateMobile());
  }

  setRequired(form: AbstractControl) {
    form.setValidators(Validators.required);
  }



  setAppointmentForApi(input, isReferral?) {
    let patient = input;
    if (input instanceof FormGroup) {
      patient = this.patientFactory.createPatient(input, 'FORMGROUP');
    }
    //
    let patientRequest: PatientRequest = new PatientRequest(
      patient
    );

    return patientRequest;
  }
}
