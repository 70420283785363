import { Component, OnInit } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";

import { AuthServiceService } from "../../services/auth/auth-service.service";

import { UserLogin } from "../../modals/user";

import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
})
export class LoginComponent implements OnInit {

  loginFormGroup: FormGroup;
  username: string;
  password: string;

  constructor(
    private router: Router,
    private authSevice: AuthServiceService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {
    this.loginFormGroup = this.createLoginFormGroup();
  }

  ngOnInit() {}

  createLoginFormGroup(): FormGroup {
    return this.fb.group({
      userName: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  onClickLogin() {
    localStorage.clear();
    this.username = this.loginFormGroup.get('userName').value;
    this.password = this.loginFormGroup.get('password').value;
    const user = new UserLogin(this.username, this.password);

    this.authSevice.login(user).subscribe({
      next: (result: any) => {
        const token = result.headers.get('Authorization')
          ? result.headers.get('Authorization').substring(7)
          : result.headers.get('authorization').substring(7);
        localStorage.setItem('access_token', token);

        const decoded_token = JSON.parse(atob(token.split('.')[1]));

        if (
          decoded_token['require-2fa'] !== undefined &&
          decoded_token['require-2fa'] !== null &&
          decoded_token['require-2fa'] === true
        ) {
          this.router.navigate([`/two-factor/${token}/${result.body.mobile}`]);
        } else {
          this.authSevice.getUserDetails();
        }
      },
      error: error => {
        this.toastr.error(error.error.message ? error.error.message : '');
      }
    });
  }

  async delay(ms: number) {
    await new Promise<void>((resolve) => setTimeout(() => resolve(), ms)).then(
      () => console.log('fired')
    );
  }
}