<!-- <app-header
  [navbarBrandRouterLink]="['/dashboard']"
  [fixed]="true"
  [navbarBrandFull]="{src: 'assets/img/brand/logo-1.png', width: 100, height: 30, alt: 'Healthway Medical'}"
  [navbarBrandMinimized]="{src: 'assets/img/brand/logo-1.png', width: 30, height: 30, alt: 'Healthway Medical'}"
  [sidebarToggler]="'lg'"
  [asideMenuToggler]="'lg'"> -->

  <app-header  [navbarBrandFull]="{src: 'assets/img/brand/logo-1.png', width: 100, height: 30, alt: 'Healthway Medical'}"
   [sidebarToggler]=false [asideMenuToggler]=false>

  <ul class="nav navbar-nav ml-auto">
    <li class="nav-item dropdown" dropdown placement="bottom right">
      <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle (click)="false">
        <i class="icon-list"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <div class="dropdown-header text-center"><strong>Account</strong></div>
        <div class="divider"></div>
        <a class="dropdown-item" href="#"><i class="fa fa-user"></i> {{userName}}</a>
        <a class="dropdown-item" href="#" (click)="onClickChangePassword()"><i class="fa fa-wrench"></i> Change Password</a>
        <a class="dropdown-item" href="#" (click)="onClickLogOut()"><i class="fa fa-lock"></i> Logout</a>
      </div>
    </li>
  </ul>
</app-header>
<div class="app-body">
  <app-sidebar [fixed]="true" [display]="'lg'">
    <app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="sidebarMinimized"></app-sidebar-nav>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <!-- Main content -->
  <main class="main">
    <!-- Breadcrumb -->
    <!-- breaking change 'cui-breadcrumb' -->
    <cui-breadcrumb>

    </cui-breadcrumb>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div><!-- /.container-fluid -->
  </main>

</div>
<app-footer>
  <span><a target="blank" href="https://www.healthwaymedical.com/">CMS</a> &copy; 2020 Healthway Medical Group.</span>
  <span class="ml-auto">Powered by <a target="blank" href="https://www.healthwaymedical.com/">Healthway Medical Group</a></span>
</app-footer>
