import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { baseUrl } from '../../../environments/environment';
import { Patient } from '../../modals/patient';
import { HttpResponseBody } from '../../modals/HttpResponseBody';


@Injectable({
  providedIn: 'root'
})
export class ApiPatientService {
  private API_URL;
  constructor(
    private http:HttpClient
  ) {
    this.API_URL = baseUrl;
   }

  updatePatientData(patientData: Patient): Observable<HttpResponseBody>{  
    if(patientData.patientInfo.gender){

      if(patientData.patientInfo.gender.toLowerCase()=="male"||patientData.patientInfo.gender.toLowerCase()=="female"){

      }
    }else{
      delete patientData.patientInfo.gender;
    }
    return this.http.post<HttpResponseBody>(`${this.API_URL}/cms-dua/patient/pre-registration/update`,JSON.stringify(patientData));
  }
}

