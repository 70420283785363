import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import * as moment from "moment";
import { DISPLAY_DATE_FORMAT, CLINICS_ARRAY, DISPLAY_TIME_NO_SECONDS_24_FORMAT, IDTYPES_ARRAY, PHONETYPE_ARRAY, EXTERNAL_USER_ROLE, NPHS_SUPERVISOR_ROLE, DB_VISIT_DATE_FORMAT, DISPLAY_DATE_TIME_NO_SECONDS_FORMAT, DB_FULL_DATE_FORMAT, } from "../../constants/app.constants";
import { FormGroup, FormBuilder, } from "@angular/forms";
import { AppointmentFormService } from "../../services/appointment/appointment-form.service";
import { DoctorAvailableSlots, DateTimeSlots, } from "../../modals/appointment";
import { UtilsService } from "../../services/util/utils.service";
import { SharedService } from "../../services/shared/shared.service";
import { ToastrService } from "ngx-toastr";
import { ApiAppointmentService } from "../../services/appointment/api-appointment.service";
import { AuthServiceService } from "../../services/auth/auth-service.service";
import { Router } from "@angular/router";
import { addDays, } from "date-fns";
var AppointmentNewComponent = /** @class */ (function () {
    function AppointmentNewComponent(bsModalRef, fb, appointmentsFormService, apiAppointmentService, utilsService, sharedService, toastr, authService, router) {
        this.bsModalRef = bsModalRef;
        this.fb = fb;
        this.appointmentsFormService = appointmentsFormService;
        this.apiAppointmentService = apiAppointmentService;
        this.utilsService = utilsService;
        this.sharedService = sharedService;
        this.toastr = toastr;
        this.authService = authService;
        this.router = router;
        this.isSaving = false;
        this.phoneType = [];
        this.disabledDates = [];
        this.isCalendarDisabled = false;
        this.isTimeDisabled = false;
        this.nextAppointment = "";
        this.disabledDays = [0, 1, 2, 3, 4, 5, 6];
        this.allAppointments = [];
        this.tempAPpointmentArray = [];
        this.minDate = new Date();
        this.maxDate = new Date();
        this.getUserRoles();
        this.appointmentForm = this.createAppointmentFormGroup();
    }
    AppointmentNewComponent.prototype.getUserRoles = function () {
        var _this = this;
        this.authService.getUser().subscribe(function (resp) {
            var payload = resp.payload;
            if (payload.roles.includes(EXTERNAL_USER_ROLE)) {
                _this.minDate.setDate(_this.minDate.getDate() + 14);
                _this.maxDate.setDate(_this.maxDate.getDate() + 60);
            }
            else if (payload.roles.includes(NPHS_SUPERVISOR_ROLE)) {
                _this.minDate.setDate(_this.minDate.getDate());
                _this.maxDate.setDate(_this.maxDate.getDate() + 300);
            }
            else {
                // for internal user no validation
                _this.minDate.setDate(_this.minDate.getDate());
                _this.maxDate.setDate(_this.maxDate.getDate() + 300);
            }
        }, function (err) {
            if (err.error.message == "Access Denied") {
                _this.router.navigate(["/login"]);
            }
            else {
                _this.toastr.error(err.error.message ? err.error.message : "");
            }
        });
    };
    AppointmentNewComponent.prototype.ngOnInit = function () {
        this.configureDatePicker();
        this.populateData();
    };
    AppointmentNewComponent.prototype.populateData = function () {
        this.clinics = CLINICS_ARRAY;
        localStorage.setItem("clinicId", CLINICS_ARRAY[0].id);
        var appointmentDate = moment(new Date()).format(DISPLAY_DATE_FORMAT);
        localStorage.setItem("appointmentDate", appointmentDate);
        this.idTypes = IDTYPES_ARRAY;
        this.phoneType = PHONETYPE_ARRAY;
        this.setMandatoryFields();
    };
    AppointmentNewComponent.prototype.getMonthlyTimeSlots = function () {
        var _this = this;
        var clinicCode = localStorage.getItem("clinicCode");
        var AppointmentTodayDate = new Date();
        var finalMonthTodayDate = moment(AppointmentTodayDate).format(DISPLAY_DATE_FORMAT);
        this.apiAppointmentService
            .getTimeSlots(clinicCode, finalMonthTodayDate)
            .subscribe(function (res) {
            console.log("here are all slots", JSON.stringify(res.payload));
            res.payload
                .filter(function (slot) { return slot.timeSlot.length > 0; })
                .forEach(function (slot) {
                var monthsDatesArray = [];
                slot.timeSlot.forEach(function (time) {
                    monthsDatesArray.push(time.calendarDayYear.date);
                });
                for (var i = 0; i < 61; i++) {
                    var monthDate = addDays(new Date(), i);
                    var finalMonthDate = moment(monthDate).format(DISPLAY_DATE_FORMAT);
                    if (monthsDatesArray.includes(finalMonthDate)) {
                    }
                    else {
                        var newdate = finalMonthDate.split("-").reverse().join("-");
                        var enteryDate = new Date(newdate);
                        _this.disabledDates.push(enteryDate);
                    }
                }
            });
            _this.disabledDays = [];
            _this.isCalendarDisabled = false;
            _this.isTimeDisabled = false;
            _this.configureDatePicker();
        }, function (err) {
            if (err.error.message == "Access Denied") {
                _this.router.navigate(["/login"]);
            }
            else {
                _this.toastr.error(err.error.message ? err.error.message : "");
            }
        });
    };
    AppointmentNewComponent.prototype.configureDatePicker = function () {
        this.bsConfig = Object.assign({}, {
            containerClass: "theme-blue",
            dateInputFormat: "DD/MM/YYYY",
            isAnimated: true,
            minDate: this.minDate,
            maxDate: this.maxDate,
            datesDisabled: this.disabledDates,
            daysDisabled: this.disabledDays,
            // placement="top"
            returnFocusToInput: true,
        });
    };
    AppointmentNewComponent.prototype.onChangeClinic = function (selectedClinic) {
        this.disabledDays = [0, 1, 2, 3, 4, 5, 6];
        this.availableTimesDropDownList = [];
        this.disabledDates = [];
        this.isCalendarDisabled = true;
        this.isTimeDisabled = false;
        this.appointmentForm.patchValue({
            startTime: "",
            appointmentTime: "",
        });
        localStorage.setItem("clinicId", selectedClinic.id);
        localStorage.setItem("clinicCode", selectedClinic.clinicCode);
        this.getMonthlyTimeSlots();
        //  this.getTimeSlots();
    };
    AppointmentNewComponent.prototype.onChangeTime = function (selectedTime) {
        localStorage.setItem("appointmentTime", selectedTime);
    };
    AppointmentNewComponent.prototype.onSearchChange = function (searchValue) {
        console.log("date change fired here", searchValue);
        console.log(searchValue);
        this.appointmentForm.patchValue({
            startTime: "",
            appointmentTime: "",
        });
    };
    AppointmentNewComponent.prototype.onchangeManual = function (value) {
        console.log("date change fired here in manual", value);
        this.isTimeDisabled = false;
        this.availableTimesDropDownList = [];
        this.appointmentForm.patchValue({
            startTime: "",
            appointmentTime: "",
        });
    };
    AppointmentNewComponent.prototype.onchangeAppointmentDate = function (value) {
        console.log("date change fired here", value);
        this.isTimeDisabled = false;
        this.availableTimesDropDownList = [];
        this.appointmentForm.patchValue({
            startTime: "",
            appointmentTime: "",
        });
        if (value == null || value == undefined) {
        }
        else {
            if (value.getTime() <= this.maxDate.getTime() && value.getTime() >= this.minDate.getTime()) {
                localStorage.setItem("appointmentDate", moment(value).format(DISPLAY_DATE_FORMAT));
                this.getAppointmentsAll();
            }
            else {
            }
        }
    };
    AppointmentNewComponent.prototype.numberOnly = function (event) {
        var charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    };
    AppointmentNewComponent.prototype.createAppointmentFormGroup = function () {
        return this.fb.group({
            appointmentDate: [""],
            patientIdentifier: [""],
            appointmentTime: [""],
            name: [""],
            idType: [""],
            idNumber: [""],
            contactNumber: [""],
            remarks: [""],
        });
    };
    AppointmentNewComponent.prototype.setMandatoryFields = function () {
        this.appointmentsFormService.setMandatoryFields(this.appointmentForm);
    };
    AppointmentNewComponent.prototype.closeModalBox = function () {
        var datepicked = moment(this.appointmentForm.get("appointmentDate").value).format(DISPLAY_DATE_FORMAT);
        this.bsModalRef.hide();
    };
    AppointmentNewComponent.prototype.closeModalBoxCancel = function () {
        this.bsModalRef.hide();
    };
    AppointmentNewComponent.prototype.getAppointmentsAll = function () {
        var _this = this;
        var clinicId = localStorage.getItem("clinicId");
        var startDate = moment(this.minDate).format(DB_VISIT_DATE_FORMAT);
        var endDate = moment(this.maxDate).format(DB_VISIT_DATE_FORMAT);
        this.apiAppointmentService
            .getAppointments(clinicId, startDate, endDate)
            .subscribe(function (res) {
            _this.tempAPpointmentArray = [];
            _this.allAppointments = res.payload;
            var tempeventsArray = [];
            var appointmentDate = localStorage.getItem("appointmentDate");
            _this.allAppointments.forEach(function (appointment) {
                appointment.startDate = moment(appointment.startDate, DB_FULL_DATE_FORMAT).format(DISPLAY_DATE_TIME_NO_SECONDS_FORMAT);
                var timeOnly = moment(appointment.startDate, DISPLAY_DATE_TIME_NO_SECONDS_FORMAT).format(DISPLAY_TIME_NO_SECONDS_24_FORMAT);
                var dateOnly = moment(appointment.startDate, DISPLAY_DATE_FORMAT).format(DISPLAY_DATE_FORMAT);
                if (appointmentDate == dateOnly) {
                    _this.tempAPpointmentArray.push(timeOnly);
                }
            });
            _this.getTimeSlots();
        }, function (err) {
            if (err.error.message == "Access Denied") {
                _this.router.navigate(["/login"]);
            }
            else {
                _this.toastr.error(err.error.message ? err.error.message : "");
            }
        });
    };
    AppointmentNewComponent.prototype.getTimeSlots = function () {
        var _this = this;
        this.isTimeDisabled = true;
        this.availableTimesDropDownList = [];
        this.availableTimes = new Array();
        var clinicId = localStorage.getItem("clinicId");
        var clinicCode = localStorage.getItem("clinicCode");
        var appointmentDate = localStorage.getItem("appointmentDate");
        if (appointmentDate != null &&
            appointmentDate != undefined &&
            appointmentDate != "") {
            this.apiAppointmentService
                .getTimeSlots(clinicCode, appointmentDate)
                .subscribe(function (res) {
                res.payload[0].timeSlot.forEach(function (SlotObj) {
                    if (SlotObj.calendarDayYear.date == appointmentDate) {
                        if (!_this.tempAPpointmentArray.includes(SlotObj.start)) {
                            _this.availableTimesDropDownList.push(SlotObj.start);
                        }
                    }
                });
                var unique = _this.availableTimesDropDownList.filter(function (elem, index, self) {
                    return index === self.indexOf(elem);
                });
                _this.availableTimesDropDownList = unique;
                _this.availableTimesDropDownList.sort();
                _this.isTimeDisabled = false;
                if (_this.availableTimesDropDownList.length > 0) {
                }
                else {
                    _this.toastr.warning("No slots available for selected date!");
                }
            }, function (err) {
                if (err.status != 200) {
                    _this.toastr.error("Could not fetch slots!");
                    return;
                }
                if (err.error.message == "Access Denied") {
                    _this.router.navigate(["/login"]);
                }
                else {
                    _this.toastr.error(err.error.message ? err.error.message : "");
                }
            });
        }
    };
    AppointmentNewComponent.prototype.getVisitTime = function () {
        var date = this.appointmentForm.get("appointmentDate")
            .value;
        var time = this.utilsService.convertDateToTime(date);
        if (this.availableTimesDropDownList) {
            var availableTimeSlot = this.availableTimesDropDownList.find(function (timeOption) {
                return moment(timeOption, DISPLAY_TIME_NO_SECONDS_24_FORMAT).isSameOrAfter(moment(time, DISPLAY_TIME_NO_SECONDS_24_FORMAT));
            });
            if (availableTimeSlot === undefined) {
                return this.utilsService.convertDateToTime(date);
            }
            else {
                return availableTimeSlot;
            }
        }
    };
    AppointmentNewComponent.prototype.mergeArray = function (array1, array2) {
        var resultArray = [];
        var arr = array1.concat(array2);
        var len = arr.length;
        var assoc = {};
        while (len--) {
            var item = arr[len];
            if (!assoc[item]) {
                resultArray.unshift(item);
                assoc[item] = true;
            }
        }
        return resultArray.sort();
    };
    AppointmentNewComponent.prototype.insertAppointment = function () {
        return this.appointmentsFormService.setAppointmentForApi(this.appointmentForm, false);
    };
    AppointmentNewComponent.prototype.setFormGroupForSaveApi = function () {
        this.appointmentForm.patchValue({
            startTime: "",
        });
        return this.appointmentsFormService.setAppointmentForApi(this.appointmentForm, false);
    };
    AppointmentNewComponent.prototype.createNewAppointmentExecute = function () {
        var _this = this;
        this.isSaving = true;
        var idValue = this.appointmentForm.get("idNumber").value;
        var idTYpeValue = this.appointmentForm.get("idType").value;
        var IdValCheck = this.sharedService.validateNRIC(idValue);
        if (!IdValCheck &&
            (idTYpeValue == "NRIC_PINK" ||
                idTYpeValue == "NRIC_BLUE" ||
                idTYpeValue == "FIN")) {
            this.toastr.error("INVALID ID CARD NUMBER");
        }
        else {
            var appointment = this.setFormGroupForSaveApi();
            appointment.preRegistration.expectedArrivalTime =
                moment(this.appointmentForm.get("appointmentDate").value).format(DISPLAY_DATE_FORMAT) +
                    "T" +
                    localStorage.getItem("appointmentTime");
            this.apiAppointmentService.create(appointment).subscribe(function (res) {
                _this.toastr.success("APPOINTMENT CREATED SUCCESSFULLY");
                _this.bsModalRef.hide();
                _this.sharedService.sendClickEvent();
            }, function (err) {
                if (err.error.message == "Access Denied") {
                    _this.router.navigate(["/login"]);
                }
                else {
                    _this.toastr.error(err.error.message ? err.error.message : "");
                }
                _this.isSaving = false;
            });
        }
    };
    AppointmentNewComponent.prototype.delay = function (ms) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(function () { return resolve(); }, ms); }).then(function () { return console.log("fired"); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppointmentNewComponent.prototype.createNewAppointment = function () {
        this.isSaving = true;
        var selectedTimeSlot = localStorage.getItem("appointmentTime");
        this.checkTimeSlots(selectedTimeSlot);
    };
    AppointmentNewComponent.prototype.checkTimeSlots = function (selectedTime) {
        var _this = this;
        var slotFlag = 0;
        this.availableTimes = new Array();
        var clinicId = localStorage.getItem("clinicId");
        var clinicCode = localStorage.getItem("clinicCode");
        var appointmentDate = localStorage.getItem("appointmentDate");
        if (appointmentDate != null &&
            appointmentDate != undefined &&
            appointmentDate != "") {
            this.apiAppointmentService
                .getTimeSlots(clinicCode, appointmentDate)
                .subscribe(function (res) {
                res.payload
                    .filter(function (slot) { return slot.timeSlot.length > 0; })
                    .forEach(function (slot) {
                    var timeSlots = new Array();
                    slot.timeSlot.forEach(function (time) {
                        timeSlots.push(time.start);
                    });
                    var dateTime = new DateTimeSlots(slot.timeSlot[0].calendarDayYear.date, timeSlots);
                    var arrayDateTimeSlots = new Array();
                    arrayDateTimeSlots.push(dateTime);
                    var doctorAvailableSlots = new DoctorAvailableSlots(slot.doctorId, arrayDateTimeSlots);
                    _this.availableTimes.push(doctorAvailableSlots);
                });
                if (_this.availableTimes.length > 0) {
                    _this.availableTimesDropDownList =
                        _this.availableTimes[0].dateTimeSlots[0].availableTimeSlots;
                }
                _this.availableTimesDropDownList.forEach(function (time) {
                    if (time == selectedTime) {
                        slotFlag = 1;
                    }
                });
                if (slotFlag == 1) {
                    _this.createNewAppointmentExecute();
                }
                else {
                    _this.toastr.warning("Time slot is not available at this time!");
                }
            }, function (err) {
                if (err.error.message == "Access Denied") {
                    _this.router.navigate(["/login"]);
                }
                else {
                    _this.toastr.error(err.error.message ? err.error.message : "");
                }
            });
        }
    };
    return AppointmentNewComponent;
}());
export { AppointmentNewComponent };
