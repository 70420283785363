<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModalBox()">
        <span arial-hidden="true">&times;</span>
    </button>

</div>
<div class="modal-body" [formGroup]="peditForm">

    <label> Enter Password</label>
    <input type="text" class="form-control" formControlName="oldPassword" placeholder="Old Password" >
    <errors [control]="peditForm.get('oldPassword')"></errors>
    <label> Enter New Password</label>
    <input type="text" class="form-control" formControlName="newPassword" placeholder="New Password " >
    <errors [control]="peditForm.get('newPassword')"></errors>
    <label> Re-enter New Password</label>
    <input type="text" class="form-control" formControlName="confirmNewPassword" placeholder="New Password Confirm" >



</div>
<div class="modal-footer">

    <button type="button" class="btn btn-warning pull-left" aria-label="Close" (click)="closeModalBox()">
        Cancel
    </button>

    <button type="button" class="btn btn-primary pull-right" [disabled]="!peditForm.valid " aria-label="Close"
        (click)="onClickLogin()">
        Update
    </button>
</div>