import { DB_FULL_DATE_FORMAT } from '../constants/app.constants';
import { Patient } from '../modals/patient';
import * as moment from 'moment';


export class PatientRequest {
  appointmentId:string;
  action: string;
  appointmentDate: string;
  patientInfo:PatientInfo


  constructor(patient: Patient) {
    this.appointmentId = patient.appointmentId;
    this.action = patient.action;
    this.appointmentDate = patient.appointmentDate;
    this.appointmentId = patient.appointmentId;
    this.patientInfo = patient.patientInfo;
  }

}
export interface UserId {
  idType: string;
  number: string;
}
export interface ContactNumber {
  number: string;
}

export interface Address {
  address: string;
  country: string;
  postalCode: string;
}
export interface PatientInfo {
  remarks:string;
  name: string;
  gender:string;
  address:Address;
  secondaryNumber:ContactNumber;
  patientIdentifier: string;
  contactNumber: ContactNumber;
  userId:UserId;
}
