import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DoctorAvailableSlots, DateTimeSlots } from '../../modals/appointment';
import { BsModalRef, } from 'ngx-bootstrap/modal';
import { ApiAppointmentService } from '../../services/appointment/api-appointment.service';
import * as moment from 'moment';
import { DISPLAY_DATE_FORMAT, DISPLAY_TIME_NO_SECONDS_24_FORMAT, DISPLAY_DATE_TIME_NO_SECONDS_FORMAT, EXTERNAL_USER_ROLE, NPHS_SUPERVISOR_ROLE } from '../../constants/app.constants';
import { SharedService } from '../../services/shared/shared.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { addDays } from 'date-fns';
import { AuthServiceService } from '../../services/auth/auth-service.service';
var AppointmentEditComponent = /** @class */ (function () {
    function AppointmentEditComponent(cd, bsModalRef, fb, sharedService, apiAppointmentService, toastr, router, authService) {
        this.cd = cd;
        this.bsModalRef = bsModalRef;
        this.fb = fb;
        this.sharedService = sharedService;
        this.apiAppointmentService = apiAppointmentService;
        this.toastr = toastr;
        this.router = router;
        this.authService = authService;
        this.isSaving = false;
        this.disabledDates = [];
        this.isCalendarDisabled = false;
        this.isTimeDisabled = false;
        this.disabledDays = [0, 1, 2, 3, 4, 5, 6];
        this.getMonthlyTimeSlots();
        this.minDate = new Date();
        this.maxDate = new Date();
        this.getUserRoles();
        this.appointmentEditForm = this.createAppointmentFormGroup();
    }
    AppointmentEditComponent.prototype.ngOnInit = function () {
        this.configureDatePicker();
        this.setInitialAppointmentData(this.appointmentDate);
    };
    AppointmentEditComponent.prototype.getUserRoles = function () {
        var _this = this;
        this.authService.getUser().subscribe(function (resp) {
            var payload = resp.payload;
            if (payload.roles.includes(EXTERNAL_USER_ROLE)) {
                _this.minDate.setDate(_this.minDate.getDate() + 14);
                _this.maxDate.setDate(_this.maxDate.getDate() + 60);
            }
            else if (payload.roles.includes(NPHS_SUPERVISOR_ROLE)) {
                _this.minDate.setDate(_this.minDate.getDate());
                _this.maxDate.setDate(_this.maxDate.getDate() + 300);
            }
            else {
                // for internal user no validation
                _this.minDate.setDate(_this.minDate.getDate());
                _this.maxDate.setDate(_this.maxDate.getDate() + 300);
            }
        }, function (err) {
            if (err.error.message == "Access Denied") {
                _this.router.navigate(['/login']);
            }
            else {
                _this.toastr.error(err.error.message ? err.error.message : "");
            }
        });
    };
    AppointmentEditComponent.prototype.getMonthlyTimeSlots = function () {
        var _this = this;
        this.disabledDays = [];
        this.isCalendarDisabled = true;
        var clinicCode = localStorage.getItem("clinicCode");
        var AppointmentTodayDate = new Date();
        var finalMonthTodayDate = moment(AppointmentTodayDate).format(DISPLAY_DATE_FORMAT);
        this.apiAppointmentService.getTimeSlots(clinicCode, finalMonthTodayDate).subscribe(function (res) {
            res.payload.filter(function (slot) { return slot.timeSlot.length > 0; }).forEach(function (slot) {
                var monthsDatesArray = [];
                var timeSlots = new Array();
                slot.timeSlot.forEach(function (time) {
                    monthsDatesArray.push(time.calendarDayYear.date);
                });
                for (var i = 0; i < 61; i++) {
                    var monthDate = addDays(new Date(), i);
                    var finalMonthDate = moment(monthDate).format(DISPLAY_DATE_FORMAT);
                    if (monthsDatesArray.includes(finalMonthDate)) {
                    }
                    else {
                        var newdate = finalMonthDate.split("-").reverse().join("-");
                        var enteryDate = new Date(newdate);
                        _this.disabledDates.push(enteryDate);
                    }
                }
            });
            _this.isCalendarDisabled = false;
        }, function (err) {
            if (err.error.message == "Access Denied") {
                _this.router.navigate(['/login']);
            }
            else {
                _this.toastr.error(err.error.message ? err.error.message : "");
            }
        });
    };
    AppointmentEditComponent.prototype.setInitialAppointmentData = function (appointmentDate) {
        var timeOnly = moment(appointmentDate, DISPLAY_DATE_TIME_NO_SECONDS_FORMAT).format(DISPLAY_TIME_NO_SECONDS_24_FORMAT);
        var dateOnly = moment(appointmentDate, DISPLAY_DATE_FORMAT).format(DISPLAY_DATE_FORMAT);
        this.appointmentEditForm.patchValue({
            appointmentDate: dateOnly
        });
        // this.getTimeSlots();
        this.appointmentEditForm.patchValue({
            appointmentDate: dateOnly,
            appointmentTime: timeOnly
        });
    };
    AppointmentEditComponent.prototype.configureDatePicker = function () {
        this.bsConfig = Object.assign({}, {
            containerClass: 'theme-blue',
            dateInputFormat: 'DD/MM/YYYY',
            isAnimated: true,
            minDate: this.minDate,
            maxDate: this.maxDate,
            datesDisabled: this.disabledDates,
            daysDisabled: this.disabledDays,
            // placement="top"
            returnFocusToInput: true,
        });
    };
    AppointmentEditComponent.prototype.createAppointmentFormGroup = function () {
        return this.fb.group({
            appointmentDate: ['', Validators.required],
            appointmentTime: ['', Validators.required],
        });
    };
    AppointmentEditComponent.prototype.onchangeAppointmentDate = function (value) {
        this.isTimeDisabled = false;
        this.cd.detectChanges();
        var editFlag = localStorage.getItem("editFlag");
        this.appointmentEditForm.patchValue({
            // appointmentTime:"",
            appointmentDate: moment(value).format(DISPLAY_DATE_FORMAT)
        });
        if (value == null || value == undefined) {
        }
        else {
            if (editFlag == "yes") {
                localStorage.setItem('editFlag', "no");
            }
            else {
                this.appointmentEditForm.patchValue({
                    appointmentTime: ''
                });
            }
            this.getTimeSlots();
        }
    };
    AppointmentEditComponent.prototype.getTimeSlots = function () {
        var _this = this;
        this.isTimeDisabled = true;
        this.cd.detectChanges();
        this.availableTimesDropDownList = [];
        this.availableTimes = new Array();
        var clinicId = localStorage.getItem("clinicId");
        var clinicCode = localStorage.getItem("clinicCode");
        var appointmentDate = this.appointmentEditForm.get('appointmentDate').value;
        this.apiAppointmentService.getTimeSlots(clinicCode, appointmentDate).subscribe(function (res) {
            res.payload[0].timeSlot.forEach(function (SlotObj) {
                if (SlotObj.calendarDayYear.date == appointmentDate) {
                    _this.availableTimesDropDownList.push(SlotObj.start);
                }
            });
            var unique = _this.availableTimesDropDownList.filter(function (elem, index, self) {
                return index === self.indexOf(elem);
            });
            _this.availableTimesDropDownList = unique;
            _this.availableTimesDropDownList.sort();
            _this.isTimeDisabled = false;
            _this.cd.detectChanges();
            if (_this.availableTimesDropDownList.length > 0) {
            }
            else {
                _this.toastr.warning("No slots available for selected date!");
            }
        }, function (err) {
            if (err.error.message == "Access Denied") {
                _this.router.navigate(['/login']);
            }
            else {
                _this.toastr.error(err.error.message ? err.error.message : "");
            }
        });
    };
    AppointmentEditComponent.prototype.checkTimeSlots = function (selectedTime) {
        var _this = this;
        var slotFlag = 0;
        this.availableTimes = new Array();
        var clinicId = localStorage.getItem("clinicId");
        var clinicCode = localStorage.getItem("clinicCode");
        var appointmentDate;
        appointmentDate = moment(this.appointmentEditForm.get('appointmentDate').value).format(DISPLAY_DATE_FORMAT);
        if (typeof this.appointmentEditForm.get('appointmentDate').value === 'string') {
            appointmentDate = this.appointmentEditForm.get('appointmentDate').value;
        }
        this.apiAppointmentService.getTimeSlots(clinicCode, appointmentDate).subscribe(function (res) {
            res.payload.filter(function (slot) { return slot.timeSlot.length > 0; }).forEach(function (slot) {
                var timeSlots = new Array();
                slot.timeSlot.forEach(function (time) {
                    timeSlots.push(time.start);
                });
                var dateTime = new DateTimeSlots(slot.timeSlot[0].calendarDayYear.date, timeSlots);
                var arrayDateTimeSlots = new Array();
                arrayDateTimeSlots.push(dateTime);
                var doctorAvailableSlots = new DoctorAvailableSlots(slot.doctorId, arrayDateTimeSlots);
                _this.availableTimes.push(doctorAvailableSlots);
            });
            if (_this.availableTimes.length > 0) {
                _this.availableTimesDropDownList = _this.availableTimes[0].dateTimeSlots[0].availableTimeSlots;
                var unique = _this.availableTimesDropDownList.filter(function (elem, index, self) {
                    return index === self.indexOf(elem);
                });
                _this.availableTimesDropDownList = unique;
            }
            _this.availableTimesDropDownList.forEach(function (time) {
                if (time == selectedTime) {
                    slotFlag = 1;
                }
            });
            if (slotFlag == 1) {
                _this.onSaveClickExcecute();
            }
            else {
                _this.toastr.warning("Time slot is not available at this time!");
            }
        }, function (err) {
            if (err.error.message == "Access Denied") {
                _this.router.navigate(['/login']);
            }
            else {
                _this.toastr.error(err.error.message ? err.error.message : "");
            }
        });
    };
    AppointmentEditComponent.prototype.mergeArray = function (array1, array2) {
        var resultArray = [];
        var arr = array1.concat(array2);
        var len = arr.length;
        var assoc = {};
        while (len--) {
            var item = arr[len];
            if (!assoc[item]) {
                resultArray.unshift(item);
                assoc[item] = true;
            }
        }
        return resultArray.sort();
    };
    AppointmentEditComponent.prototype.onTimeSelect = function (selectedTime) {
        if (selectedTime != undefined && selectedTime != null) {
            this.appointmentEditForm.patchValue({
                appointmentTime: selectedTime
            });
        }
    };
    AppointmentEditComponent.prototype.onSaveClick = function () {
        this.checkTimeSlots(this.appointmentEditForm.get('appointmentTime').value);
    };
    ;
    AppointmentEditComponent.prototype.onSaveClickExcecute = function () {
        var _this = this;
        this.isSaving = true;
        var appointmentDate;
        appointmentDate = moment(this.appointmentEditForm.get('appointmentDate').value).format(DISPLAY_DATE_FORMAT);
        if (typeof this.appointmentEditForm.get('appointmentDate').value === 'string') {
            appointmentDate = this.appointmentEditForm.get('appointmentDate').value;
        }
        var newDate = appointmentDate + "T" + this.appointmentEditForm.get('appointmentTime').value;
        //start
        var appointmentId = this.appointmentId;
        this.apiAppointmentService.updateAppointments(appointmentId, newDate).subscribe(function (res) {
            _this.toastr.success("Appointment Updated Successfully ");
            _this.bsModalRef.hide();
            _this.sharedService.sendClickEvent();
        }, function (err) {
            if (err.error.message == "Access Denied") {
                _this.router.navigate(['/login']);
            }
            else {
                _this.toastr.error(err.error.message ? err.error.message : "");
            }
        });
    };
    AppointmentEditComponent.prototype.closeModalBox = function () {
        this.bsModalRef.hide();
    };
    AppointmentEditComponent.prototype.delay = function (ms) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(function () { return resolve(); }, ms); }).then(function () { return console.log("fired"); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return AppointmentEditComponent;
}());
export { AppointmentEditComponent };
