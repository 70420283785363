<div class="app-body">
    <main class="main d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-md-8 mx-auto">
                    <div class="card-group">
                        <div class="card p-4">
                            <form [formGroup]="form" (submit)="onSubmit()">
                                <div class="card-body">
                                    <h1>Two-step Verification</h1>
                                    <p class="text-muted">Please enter the code you received to your mobile number ending with {{ mobile }}.</p>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="icon-lock"></i></span>
                                        </div>
                                        <input 
                                            type="number" 
                                            class="form-control" 
                                            formControlName="twoFactorPin"
                                            placeholder="OTP" 
                                            required>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <button 
                                                type="submit"
                                                [disabled]="form.invalid"
                                                class="btn btn-primary px-4">
                                                Login
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>