
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { baseUrl } from '../../../environments/environment';
import { UserLogin } from '../../modals/user';
import { HttpResponseBody } from '../../modals/HttpResponseBody';
import { JsonPipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ApiAppointmentService {
  private API_URL;
  constructor(private http: HttpClient) {
    this.API_URL = baseUrl;

  }

  getTimeSlots(clinicCode: any, appointmentDate: any): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(`${this.API_URL}/cms-dua/patient/pre-registration/tele-consult/available/time-slot/${clinicCode}/${appointmentDate}`,
      ["EXT_APP_DUMMY_MCR"]);




  }

  getTimeSlotsPublic(clinicCode: any, appointmentDate: any): Observable<HttpResponseBody> {
    let uniqueId;
    return this.http.post<HttpResponseBody>(`${this.API_URL}/cms-dua/public-appointments/35de1f66/available/time-slot/${clinicCode}/${appointmentDate}`,
      ["EXT_APP_DUMMY_MCR"]);
  }

  getAppointments(clinicId: any, startDate: any, endDate: any): Observable<HttpResponseBody> {
    let obj = {
      "clinicId": clinicId != null ? clinicId : localStorage.getItem("clinicId"),
      "startDate": startDate,// "02-10-2020T22:45:00",
      "endDate": endDate
    }
    return this.http.post<HttpResponseBody>(`${this.API_URL}/cms-dua/patient/pre-registration/list/calendar`, JSON.stringify(obj));
  }


  updateAppointments(appointmentId: any, newDate: any): Observable<HttpResponseBody> {
    let obj = {
      "appointmentId": appointmentId,
      "action": "RESCHEDULED",
      "appointmentDate": newDate
    }
    return this.http.post<HttpResponseBody>(`${this.API_URL}/cms-dua/patient/pre-registration/update`, JSON.stringify(obj));
  }

  create(appointment: any): Observable<HttpResponseBody> {
    var obj = {
      "number": appointment.contactNumber
    }
    appointment.contactNumber = obj;
    appointment.duration = 30;
    appointment.preRegistration.doctorMcr = "EXT_APP_DUMMY_MCR";
    appointment.preRegistration.remark = appointment.remark;
    if (appointment.userId.idType == null || appointment.userId.idType == "" || appointment.userId.number == null || appointment.userId.number == "") {
      appointment.userId.idType = "OTHER";
      appointment.userId.number = appointment.patientIdentifier;
    }
    return this.http.post<HttpResponseBody>(
      `${this.API_URL}/cms-dua/patient/pre-registration/create`,
      JSON.stringify(appointment)
    );
  }

  createAppointment(appointment: any): Observable<HttpResponseBody> {
    var obj = {
      "number": appointment.contactNumber
    }
    appointment.contactNumber = obj;
    appointment.duration = 30;
    appointment.preRegistration.doctorMcr = "EXT_APP_DUMMY_MCR";
    appointment.preRegistration.remark = appointment.remark;
    if (appointment.userId.idType == null || appointment.userId.idType == "" || appointment.userId.number == null || appointment.userId.number == "") {
      appointment.userId.idType = "OTHER";
      appointment.userId.number = appointment.patientIdentifier;
    }
    return this.http.post<HttpResponseBody>(
      `${this.API_URL}/cms-dua/public-appointments/35de1f66/pre-register/NPHS`,
      JSON.stringify(appointment)
    );
  }



  updatePassword(oldPassword: string, newPassword: string): Observable<HttpResponseBody> {
    let oldPasswordValue = oldPassword;
    let newPasswordValue = newPassword;
    return this.http.post<HttpResponseBody>(`${this.API_URL}/aacore/user/change/password/${oldPasswordValue}/${newPasswordValue}`, {});
  }

  remove(appointmentId: any): Observable<HttpResponseBody> {
    let obj = {
      "appointmentId": appointmentId,
      "action": "CANCELED"
    }
    return this.http.post<HttpResponseBody>(`${this.API_URL}/cms-dua/patient/pre-registration/update`, JSON.stringify(obj));
  }

  updatePassword1(oldPassword: string, newPassword: string): Observable<HttpResponseBody> {
    let oldPasswordValue = oldPassword;
    let newPasswordValue = newPassword;
    return this.http.post<HttpResponseBody>(`${this.API_URL}/aacore/user/change/password/${oldPasswordValue}/${newPasswordValue}`, {});
  }
}

