import { Component, OnInit, Directive, HostListener, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/ngx-bootstrap-datepicker';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DoctorAvailableSlots, DateTimeSlots, Appointment } from '../../modals/appointment';
import { BsModalService, BsModalRef, } from 'ngx-bootstrap/modal'
import { ApiAppointmentService } from '../../services/appointment/api-appointment.service';
import * as moment from 'moment';
import { DISPLAY_DATE_FORMAT, DISPLAY_TIME_NO_SECONDS_24_FORMAT, DISPLAY_DATE_TIME_NO_SECONDS_FORMAT, DISPLAY_DATE_TIME, DB_FULL_DATE_FORMAT, EXTERNAL_USER_ROLE, NPHS_SUPERVISOR_ROLE } from '../../constants/app.constants';
import { SharedService } from '../../services/shared/shared.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours, addMinutes } from 'date-fns';
import { AuthServiceService } from '../../services/auth/auth-service.service';

@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-appointment-edit',
  templateUrl: './appointment-edit.component.html',
  styleUrls: ['./appointment-edit.component.scss']
})


export class AppointmentEditComponent implements OnInit {
  title: string;
  appointmentDate: string;
  appointmentId: string;
  bsConfig: Partial<BsDatepickerConfig>;
  minDate: Date;
  maxDate: Date;
  appointmentEditForm: FormGroup;
  availableTimes: Array<DoctorAvailableSlots>;
  availableTimesDropDownList: Array<string>;
  isSaving = false;
  disabledDates = [];
  isCalendarDisabled = false;
  isTimeDisabled = false;
  disabledDays = [0, 1, 2, 3, 4, 5, 6];
  constructor(
    private cd: ChangeDetectorRef,
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private sharedService: SharedService,
    private apiAppointmentService: ApiAppointmentService,
    private toastr: ToastrService,
    private router: Router,
    private authService: AuthServiceService,
  ) {
    this.getMonthlyTimeSlots();
    this.minDate = new Date();
    this.maxDate = new Date();
    this.getUserRoles();
    this.appointmentEditForm = this.createAppointmentFormGroup();
  }

  ngOnInit() {
   
    this.configureDatePicker();
    this.setInitialAppointmentData(this.appointmentDate);

  }
  getUserRoles() {
    this.authService.getUser().subscribe(
      resp => {
        let { payload } = resp;
        if (payload.roles.includes(EXTERNAL_USER_ROLE)) {
     

          this.minDate.setDate(this.minDate.getDate()+ 14);
          this.maxDate.setDate(this.maxDate.getDate() + 60);
        } else if (payload.roles.includes(NPHS_SUPERVISOR_ROLE)) {
       
          this.minDate.setDate(this.minDate.getDate());
          this.maxDate.setDate(this.maxDate.getDate() + 300);
        }
        else {
// for internal user no validation
          this.minDate.setDate(this.minDate.getDate());
          this.maxDate.setDate(this.maxDate.getDate() + 300);
        }

      },
      err => {
        if (err.error.message == "Access Denied") {
          this.router.navigate(['/login'])
        } else {
          this.toastr.error(err.error.message ? err.error.message : "")
        }
      }
    );
  }


  getMonthlyTimeSlots() {
    this.disabledDays = [];
    this.isCalendarDisabled = true;
    let clinicCode = localStorage.getItem("clinicCode");
    var AppointmentTodayDate = new Date();
    var finalMonthTodayDate = moment(AppointmentTodayDate).format(
      DISPLAY_DATE_FORMAT
    )
    this.apiAppointmentService.getTimeSlots(clinicCode, finalMonthTodayDate).subscribe(
      res => {
        res.payload.filter(slot => slot.timeSlot.length > 0).forEach(slot => {
          var monthsDatesArray = [];
          let timeSlots = new Array<string>();
          slot.timeSlot.forEach(time => {
            monthsDatesArray.push(time.calendarDayYear.date);
          });
          for (let i = 0; i < 61; i++) {
            var monthDate = addDays(new Date(), i);
            var finalMonthDate = moment(monthDate).format(
              DISPLAY_DATE_FORMAT
            )
            if (monthsDatesArray.includes(finalMonthDate)) {
            } else {
              var newdate = finalMonthDate.split("-").reverse().join("-");
              var enteryDate = new Date(newdate);
              this.disabledDates.push(enteryDate);
            }
          }
        });

        this.isCalendarDisabled = false;


      },
      err => {
        if (err.error.message == "Access Denied") {
          this.router.navigate(['/login'])
        } else {
          this.toastr.error(err.error.message ? err.error.message : "")
        }

      }

    );

  }
  setInitialAppointmentData(appointmentDate: string) {


    let timeOnly = moment(appointmentDate, DISPLAY_DATE_TIME_NO_SECONDS_FORMAT).format(
      DISPLAY_TIME_NO_SECONDS_24_FORMAT
    );

    let dateOnly = moment(appointmentDate, DISPLAY_DATE_FORMAT).format(
      DISPLAY_DATE_FORMAT
    );

    this.appointmentEditForm.patchValue({
      appointmentDate: dateOnly
    });
    // this.getTimeSlots();
    this.appointmentEditForm.patchValue({
      appointmentDate: dateOnly,
      appointmentTime: timeOnly
    });

  }

  configureDatePicker() {
    this.bsConfig = Object.assign({}, {
      containerClass: 'theme-blue',
      dateInputFormat: 'DD/MM/YYYY',
      isAnimated: true,
      minDate: this.minDate,
      maxDate: this.maxDate,
      datesDisabled: this.disabledDates,
      daysDisabled: this.disabledDays,
      // placement="top"
      returnFocusToInput: true,

    });
  }
  createAppointmentFormGroup(): FormGroup {
    return this.fb.group({
      appointmentDate: ['', Validators.required],
      appointmentTime: ['', Validators.required],
    });



  }

  onchangeAppointmentDate(value: Date): void {
    this.isTimeDisabled = false;
    this.cd.detectChanges();
    let editFlag = localStorage.getItem("editFlag");

    this.appointmentEditForm.patchValue({
      // appointmentTime:"",
      appointmentDate: moment(value).format(
        DISPLAY_DATE_FORMAT
      )
    });


    if (value == null || value == undefined) {

    } else {
      if (editFlag == "yes") {
        localStorage.setItem('editFlag', "no");
      } else {
        this.appointmentEditForm.patchValue({
          appointmentTime: ''
        });
      }

      this.getTimeSlots();
    }

  }


  getTimeSlots() {
    this.isTimeDisabled = true;
    this.cd.detectChanges();
    this.availableTimesDropDownList = [];
    this.availableTimes = new Array<DoctorAvailableSlots>();
    let clinicId = localStorage.getItem("clinicId");
    let clinicCode = localStorage.getItem("clinicCode");
    let appointmentDate = this.appointmentEditForm.get('appointmentDate').value;
    this.apiAppointmentService.getTimeSlots(clinicCode, appointmentDate).subscribe(

      res => {
        res.payload[0].timeSlot.forEach(SlotObj => {
          if (SlotObj.calendarDayYear.date == appointmentDate) {

            this.availableTimesDropDownList.push(SlotObj.start);
          }
        });
        var unique = this.availableTimesDropDownList.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });
        this.availableTimesDropDownList = unique;
        this.availableTimesDropDownList.sort();
        this.isTimeDisabled = false;
        this.cd.detectChanges();

        if(this.availableTimesDropDownList.length>0){

        }else{
          this.toastr.warning("No slots available for selected date!");

        }
      },

      err => {

        if (err.error.message == "Access Denied") {
          this.router.navigate(['/login'])
        } else {
          this.toastr.error(err.error.message ? err.error.message : "")
        }
      }

    );

  }


  checkTimeSlots(selectedTime) {
    var slotFlag = 0;
    this.availableTimes = new Array<DoctorAvailableSlots>();
    let clinicId = localStorage.getItem("clinicId");
    let clinicCode = localStorage.getItem("clinicCode");
    let appointmentDate;
    appointmentDate = moment(this.appointmentEditForm.get('appointmentDate').value).format(
      DISPLAY_DATE_FORMAT
    );
    
    if (typeof this.appointmentEditForm.get('appointmentDate').value === 'string') {
      appointmentDate = this.appointmentEditForm.get('appointmentDate').value;
    }

    this.apiAppointmentService.getTimeSlots(clinicCode, appointmentDate).subscribe(
      res => {
        res.payload.filter(slot => slot.timeSlot.length > 0).forEach(slot => {
          let timeSlots = new Array<string>();
          slot.timeSlot.forEach(time => {
            timeSlots.push(time.start);
          });
          const dateTime: DateTimeSlots = new DateTimeSlots(
            slot.timeSlot[0].calendarDayYear.date,
            timeSlots
          );
          const arrayDateTimeSlots = new Array<DateTimeSlots>();
          arrayDateTimeSlots.push(dateTime);

          const doctorAvailableSlots = new DoctorAvailableSlots(
            slot.doctorId,
            arrayDateTimeSlots
          );
          this.availableTimes.push(doctorAvailableSlots);

        });
        if (this.availableTimes.length > 0) {
          this.availableTimesDropDownList = this.availableTimes[0].dateTimeSlots[0].availableTimeSlots;
          var unique = this.availableTimesDropDownList.filter(function (elem, index, self) {
            return index === self.indexOf(elem);
          });
          this.availableTimesDropDownList = unique;
        }
        this.availableTimesDropDownList.forEach(time => {

          if (time == selectedTime) {

            slotFlag = 1;
          }
        });
        if (slotFlag == 1) {
          this.onSaveClickExcecute();
        } else {
          this.toastr.warning("Time slot is not available at this time!")
        }
      },
      err => {

        if (err.error.message == "Access Denied") {
          this.router.navigate(['/login'])
        } else {
          this.toastr.error(err.error.message ? err.error.message : "")
        }
      }
    );
  }


  mergeArray(array1, array2) {
    let resultArray = [];
    let arr = array1.concat(array2);
    let len = arr.length;
    let assoc = {};

    while (len--) {
      let item = arr[len];

      if (!assoc[item]) {
        resultArray.unshift(item);
        assoc[item] = true;
      }
    }
    return resultArray.sort();
  }

  onTimeSelect(selectedTime) {
    if (selectedTime != undefined && selectedTime != null) {
      this.appointmentEditForm.patchValue({
        appointmentTime: selectedTime
      });

    }

  }
  onSaveClick() {
    this.checkTimeSlots(this.appointmentEditForm.get('appointmentTime').value);
  };

  onSaveClickExcecute() {
    this.isSaving = true;

    let appointmentDate;
    appointmentDate = moment(this.appointmentEditForm.get('appointmentDate').value).format(
      DISPLAY_DATE_FORMAT
    );
    if (typeof this.appointmentEditForm.get('appointmentDate').value === 'string') {
      appointmentDate = this.appointmentEditForm.get('appointmentDate').value;
    }

    let newDate = appointmentDate + "T" + this.appointmentEditForm.get('appointmentTime').value;
    //start
    let appointmentId = this.appointmentId;
    this.apiAppointmentService.updateAppointments(appointmentId, newDate).subscribe(
      res => {
        this.toastr.success("Appointment Updated Successfully ");
        this.bsModalRef.hide();
        this.sharedService.sendClickEvent();
      },
      err => {
        if (err.error.message == "Access Denied") {
          this.router.navigate(['/login'])
        } else {
          this.toastr.error(err.error.message ? err.error.message : "")
        }


      }
    );

  }
  closeModalBox() {

    this.bsModalRef.hide();
  }
  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("fired"));
  }

}
