import { Component, OnInit, ɵConsole, HostListener, Directive, ElementRef } from '@angular/core';
import { BsModalService, BsModalRef, } from 'ngx-bootstrap/modal'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IdTypes } from '../../modals/idTypes';
import { ContactNumber, UserId, Patient } from '../../modals/patient';
import { IDTYPES_ARRAY, PHONETYPE_ARRAY, GENDER_ARRAY, DISPLAY_DATE_FORMAT, DB_VISIT_DATE_FORMAT_NO_SECOND, DISPLAY_DATE_TIME_NO_SECONDS_FORMAT, DISPLAY_TIME_NO_SECONDS_24_FORMAT } from '../../constants/app.constants';
import { PatientFormService } from '../../services/patient/patient-form.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { SharedService } from '../../services/shared/shared.service';
import { ToastrService } from 'ngx-toastr';
import { ApiPatientService } from '../../services/patient/api-patient.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
@Component({
  selector: 'app-patient-edit',
  templateUrl: './patient-edit.component.html',
  styleUrls: ['./patient-edit.component.scss']
})


export class PatientEditComponent implements OnInit {
  title: string;
  isSaving = false;
  iappointmentDate: string;
  iappointmentId: string;
  ipatientIdentifier: string;
  iname: string;
  iremarks: string;
  iidType: string;
  iidNumber: string;
  igender: string;
  iphoneType: string;
  icontactNumber: string;
  isecondaryNumber: string;
  ipostalCode: string;
  icountry: string;
  iaddress: string;
  minDate: Date;
  maxDate: Date;
  patientForm: FormGroup;
  bsConfig: Partial<BsDatepickerConfig>;
  idTypes: Array<IdTypes>;
  phoneType = [];
  genderType = [];
  constructor(
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private patientsFormService: PatientFormService,
    private apiPatientService: ApiPatientService,
    private sharedService: SharedService,
    private toastr: ToastrService,
    private elementRef: ElementRef,
    private router: Router,
  ) {
    this.minDate = new Date();
    this.maxDate = new Date();
    this.minDate.setDate(this.minDate.getDate() - 5000);
    this.maxDate.setDate(this.maxDate.getDate());
    this.patientForm = this.createPatientFormGroup();
  }
  ngOnInit() {
    this.populateData();
  }

  private populateData() {
    let timeOnly = moment(this.iappointmentDate, DISPLAY_DATE_TIME_NO_SECONDS_FORMAT).format(
      DISPLAY_TIME_NO_SECONDS_24_FORMAT
    );
    let dateOnly = moment(this.iappointmentDate, DISPLAY_DATE_FORMAT).format(
      DISPLAY_DATE_FORMAT
    );
    let finaldate = dateOnly + "T" + timeOnly;
    localStorage.setItem('iappointmentDate', finaldate);
    this.idTypes = IDTYPES_ARRAY;
    this.phoneType = PHONETYPE_ARRAY;
    this.genderType = GENDER_ARRAY;
    this.setMandatoryFields();
    this.patientForm.patchValue({
      appointmentDate: this.iappointmentDate ? this.iappointmentDate : "",
      appointmentId: this.iappointmentId ? this.iappointmentId : "",
      patientIdentifier: this.ipatientIdentifier ? this.ipatientIdentifier : "",
      name: this.iname ? this.iname : "",
      remarks: this.iremarks ? this.iremarks : "",
      idType: this.iidType ? this.iidType : "",
      phoneType: this.iphoneType ? this.iphoneType : "",
      idNumber: this.iidNumber ? this.iidNumber : "",
      contactNumber: this.icontactNumber ? this.icontactNumber : "",
      secondaryNumber: this.isecondaryNumber ? this.isecondaryNumber : "",
      postalCode: this.ipostalCode ? this.ipostalCode : "",
      country: this.icountry ? this.icountry : "",
      address: this.iaddress ? this.iaddress : "",
      gender: this.igender ? this.igender : "",
    });
    this.configureDatePicker();
  }
  configureDatePicker() {
    this.bsConfig = Object.assign({}, {
      containerClass: 'theme-blue',
      dateInputFormat: 'DD-MM-YYYY',
      isAnimated: true,
      // minDate:this.minDate,
      maxDate: this.maxDate,
      // daysDisabled:[6,0]
      // placement="top"
      returnFocusToInput: true
    });
  }

  titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }
  setMandatoryFields() {
    this.patientsFormService.setMandatoryFields(
      this.patientForm,
    );
  }
  onchangeAppointmentDate(value: any): void {
    if (value == null || value == undefined) {

    } else {
      this.patientForm.patchValue({
        dob: moment(value).format(DISPLAY_DATE_FORMAT)
      });
    }

  }

  createPatientFormGroup(): FormGroup {
    return this.fb.group({
      appointmentId: [''],
      appointmentDate: [''],
      patientIdentifier: [''],
      appointmentTime: [''],
      name: [''],
      remarks: [''],
      idType: ['', Validators.required],
      phoneType: [''],
      idNumber: [''],
      contactNumber: [''],
      postalCode: [''],
      country: ['SINGAPORE'],
      address: [''],
      gender: [''],
      secondaryNumber: [''],
    });

  }
  closeModalBox() {
    this.bsModalRef.hide();
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  setFormGroupForSaveApi() {
    return this.patientsFormService.setAppointmentForApi(
      this.patientForm,
      false
    );
  }

  updatePatientInfo() {
    this.isSaving = true;
    let idValue = this.patientForm.get('idNumber').value;
    let idTYpeValue = this.patientForm.get('idType').value;
    let IdValCheck = this.sharedService.validateNRIC(idValue)
    if (!IdValCheck && (idTYpeValue == "NRIC_PINK" || idTYpeValue == "NRIC_BLUE" || idTYpeValue == "FIN")) {
      this.toastr.error("INVALID ID CARD NUMBER");
    } else {
      const patient = this.setFormGroupForSaveApi();
      this.apiPatientService.updatePatientData(patient).subscribe(
        res => {
          this.toastr.success("PATIENT DETAILS UPDATED SUCCESSFULLY");
          this.bsModalRef.hide();
          this.sharedService.sendClickEvent();

        },
        err => {
          if (err.error.message == "Access Denied") {
            this.router.navigate(['/login'])
          } else {
            this.toastr.error(err.error.message ? err.error.message : "")
          }
          this.isSaving = false;

        }
      );
    }
  }
}
