import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthServiceService } from '../services/auth/auth-service.service';

@Injectable({
  providedIn: 'root'
})
export class AppointmentAuthGuard implements CanActivate {

  constructor(
    private _authService: AuthServiceService,
    private _router: Router

  ) { }
  canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot): boolean {
    let queryParams = activatedRouteSnapshot.queryParams;
    if (queryParams.serialHi) {
      return true;
    } else {
      this._router.navigate(['/login'])
      return false;
    }
  }

}
