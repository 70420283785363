import { Component, OnDestroy, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { navItems } from '../../_nav';
import { Router } from '@angular/router';
import { BsModalService, } from 'ngx-bootstrap/modal'
import { PasswordResetComponent } from '../../views/password-reset/password-reset.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit,OnDestroy {
  userName:string;
  public navItems = navItems;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement;
  constructor(
    private router: Router,
     private modalService: BsModalService,
    @Inject(DOCUMENT) _document?: any) {

    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
    });
    this.element = _document.body;
    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: ['class']
    });
  }
  ngOnInit() {
   this.userName= localStorage.getItem('firstName')?localStorage.getItem('firstName'):"NA";
  }
  ngOnDestroy(): void {
    this.changes.disconnect();
  }


  onClickLogOut() {
    localStorage.clear();
    this.router.navigate(['/login'])
  
  }
  onClickChangePassword(){
    const initialState = {
      title: 'Update Password'
    };

    this.modalService.show(PasswordResetComponent, {
      initialState,
      class: 'modal-lg',
      backdrop: 'static',
    });
  }
}
