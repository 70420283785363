<div class="app-body margin-top">
    <main class="main d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-md-12 mx-auto appointment-form">
                    <div class="card-group">
                        <div class="card p-4">
                            <form [formGroup]="appointmentForm" (submit)="onSubmit()">
                                <div class="card-header bg-white pt-0">
                                    <h1 class="header">NPHS Appointment</h1>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-md-4 mb-2">
                                                    <div class="wrapper-form-control">
                                                        <label class='mb-0'>Select Clinic</label>
                                                        <ng-select
                                                            class="custom-select-new custom-select-extra-medium-width"
                                                            [items]="clinics" [hideSelected]="true" bindLabel='name'
                                                            bindValue='id' (change)="onChangeClinic($event)"
                                                            formControlName="clinicId"></ng-select>
                                                        <errors [control]="appointmentForm.get('clinicId')"></errors>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 mb-2">
                                                    <div class="wrapper-form-control">
                                                        <label class='mb-0'>Appointment Date</label>
                                                        <input type="text" class="input datepicker"
                                                            placeholder="DD/MM/YYYY" [bsConfig]="bsConfig"
                                                            (input)="onchangeManual($event.target.value)"
                                                            (bsValueChange)="onchangeAppointmentDate($event)"
                                                            bsDatepicker formControlName="appointmentDate">
                                                        <errors [control]="appointmentForm.get('appointmentDate')"></errors>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 mb-2">
                                                    <div class="wrapper-form-control">
                                                        <label class='mb-0'>Appointment Time</label>
                                                        <ng-select
                                                            class="custom-select-new custom-select-extra-medium-width"
                                                            [items]="availableTimesDropDownList"
                                                            onkeypress="return false;" (change)="onChangeTime($event)"
                                                            formControlName="appointmentTime"></ng-select>
                                                        <errors [control]="appointmentForm.get('appointmentTime')"></errors>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4 mb-2">
                                                    <div class="wrapper-form-control">
                                                        <label class='mb-0'>Name(As per NRIC)</label>
                                                        <input class="input" placeholder="Enter Name"
                                                            formControlName="name" />
                                                        <errors [control]="appointmentForm.get('name')"></errors>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 mb-2">
                                                    <div class="wrapper-form-control">
                                                        <label class='mb-0'>Identification Type</label>
                                                        <ng-select
                                                            class="custom-select-new custom-select-extra-medium-width"
                                                            [items]="idTypes" bindLabel="label" bindValue="value"
                                                            [hideSelected]="true" formControlName="idType"></ng-select>
                                                        <errors [control]="appointmentForm.get('idType')"></errors>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 mb-2">
                                                    <div class="wrapper-form-control">
                                                        <label class='mb-0'>Identification Number</label>
                                                        <input class="input" formControlName="idNumber"
                                                            placeholder="NRIC" />
                                                        <errors [control]="appointmentForm.get('idNumber')"></errors>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4 mb-2">
                                                    <div class="wrapper-form-control">
                                                        <label class='mb-0'>SerialHi</label>
                                                        <input class="input" placeholder="Enter Serial Hi"
                                                            formControlName="patientIdentifier" readonly />
                                                        <small class="error-msg" *ngIf="appointmentForm.get('patientIdentifier').errors"> SerialHi ID is not valid.</small>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 mb-2">
                                                    <div class="wrapper-form-control">
                                                        <label class='mb-0'>Select Mobile/Home</label>
                                                        <ng-select
                                                            class="custom-select-new custom-select-extra-medium-width"
                                                            [items]="phoneType" bindLabel="value" bindValue="value"
                                                            value="value" [clearable]=false>
                                                        </ng-select>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 mb-2">
                                                    <div class="wrapper-form-control">
                                                        <label class='mb-0'>Contact Number</label>
                                                        <input class="input" formControlName="contactNumber"
                                                            placeholder="e.g. +65 91234567" />
                                                        <errors [control]="appointmentForm.get('contactNumber')"></errors>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-8 mb-2">
                                                    <div class="wrapper-form-control">
                                                        <label class='mb-0'>Remark</label>
                                                        <textarea class="input" formControlName="remarks"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6"></div>
                                        <div class="col-md-3 mt-2">
                                            <div class="wrapper-form-control">
                                                <button type="button" (click)="onCancel()"
                                                    class="button-green pull-right">
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                        <div class="col-md-3 mt-2">
                                            <button type="submit" [disabled]="isClickedOnce"class="button-primary">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="row m-0">
                                            <div class="col-md-12">
                                                <table class="table table-striped table-ex-appointment">
                                                    <thead>
                                                        <tr>
                                                            <th>Clinic Name</th>
                                                            <th>Clinic Address</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of clinics; let i = index;">
                                                            <td>{{item.name}}</td>
                                                            <td>{{item.address.address}}, {{item.address.postalCode}}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="note-text">For any enquiries or assistance, please contact our Hotline:
                                            <a href="tel:68178306">68178306</a><span>/</span><a href="tel:68178307">68178307</a>
                                        </div>
                                        <div class="note-text">
                                            <strong>Hotline Operation Hours:</strong>
                                            <ul class="m-0 p-0">
                                                <li>Monday to Friday: 9:00 am to 5:00 pm</li>
                                                <li>Saturday: 9:00 am to 1:00 pm</li>
                                                <li>Sunday and Public Holidays: Closed</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>