
    <small>
      <ul class="p-0 m-0" *ngIf="showErrors()">
        <li
          style="color: red; list-style-type: none;"
          *ngFor="let error of errors()"
        >
          {{ error }}
        </li>
      </ul>
    </small>
  