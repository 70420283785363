

//   <input (keydown.enter)="...">
// <input (keydown.a)="...">
// <input (keydown.esc)="...">
// <input (keydown.shift.esc)="...">
// <input (keydown.control)="...">
// <input (keydown.alt)="...">
// <input (keydown.meta)="...">
// <input (keydown.9)="...">
// <input (keydown.tab)="...">
// <input (keydown.backspace)="...">
// <input (keydown.arrowup)="...">
// <input (keydown.shift.arrowdown)="...">
// <input (keydown.shift.control.z)="...">
// <input (keydown.f4)="...">

export const DISPLAY_DATE_FORMAT = 'DD-MM-YYYY';
export const DISPLAY_DATE_TIME_NO_SECONDS_FORMAT = 'DD-MM-YYYY HH:mm';
export const DISPLAY_DATE_TIME_NO_SECONDS_FORMATB = 'MM-DD-YYYY HH:mm';
export const DISPLAY_DATE_TIME = 'DD-MM-YYYY HH:mm:ss';
export const DISPLAY_TIME_NO_SECONDS_FORMAT = 'hh:mm';
export const DISPLAY_TIME_NO_SECONDS_24_FORMAT = 'HH:mm';
export const DISPLAY_TIME_FORMAT_WIH_SLASHESH = 'DD/MM/YYYY HH:mm:ss';
export const INVENTORY_DATE_FORMAT = 'YYYY-MM-DD';
export const DB_FULL_DATE_FORMAT = 'DD-MM-YYYYTHH:mm:ss';
export const DB_FULL_DATE_FORMATB = 'MM-DD-YYYYTHH:mm:ss';
export const DB_FULL_DATE_FORMAT_ZERO_SECOND = 'DD-MM-YYYYTHH:mm:00';
export const DB_FULL_DATE_FORMAT_NO_SECOND = 'DD-MM-YYYYTHH:mm';
export const DB_FULL_DATE_TIMEZONE = 'ddd MMM DD YYYY HH:mm:ss ZZ';
export const DB_FULL_DATE_TIMEZONE_NO_SPACE = 'YYYY-MM-DDTHH:mm:ss.SSS';
export const DB_FULL_DATE_TIMEZONE_NO_SPACE_REVERSE = 'DD-MM-YYYYTHH:mm:ss.SSS';
export const DB_FULL_DATE_TIMEZONE_NO_SPACE_REVERSEC = 'YYYY-MM-DDTHH:mm:ss.sssZ';
export const DB_FULL_DATE_TIMEZONE_Z1 = 'YYYY-MM-DDTHH:mm:ssZ';
export const DB_FULL_DATE_TIMEZONE_Z = 'YYYY-MM-DD HH:mm:ss';
export const DB_VISIT_DATE_FORMAT = 'DD-MM-YYYYT00:00:00';
export const DB_VISIT_DATE_FORMAT_NO_SECOND = 'DD-MM-YYYYT00:00';
export const DAY_OF_WEEK = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];
export const DAY_INT_OF_WEEK = [
  { MONDAY: 1 },
  { TUESDAY: 2 },
  { WEDNESDAY: 3 },
  { THURSDAY: 4 },
  { FRIDAY: 5 },
  { SATURDAY: 6 },
  { SUNDAY: 7 },
];
export const TABS = {
  PROFILE: 'Profile',
  VITALS: 'Vital Signs',
  ASSESSMENTS: 'Assessments',
  HISTORY: 'History',
  CONSULTATION: 'Consultation',
  PCN: 'Primary Care Network',
  SERVICES: 'Medical Services',
  COVERAGE: 'Coverage',
  DOCUMENTS: 'Documents',
  DISPENSING: 'Dispensing',
  PRINTING: 'Printing',
  PAYMENT: 'Payment',
};

export enum PCNFORMS {
  PATIENTEXTRADETAILSFORM = 'patientExtraDetailsForm',
  PATIENTCONDITIONFORM = 'patientConditionForm',
  COMPLICATIONFORM = 'complicationForm',
  VITALFORM = 'vitalForm',
  ASSESSMENTFORM = 'assessmentForm',
}

export const DOCTOR_BLOCKED_TIME = 'DOCTOR_BLOCKED_TIME';
export const CLINIC_BLOCKED_TIME = 'CLINIC_BLOCKED_TIME';
export const DOCTOR_LEAVE = 'DOCTOR_LEAVE';
export const CLINIC_HOLIDAY = 'CLINIC_HOLIDAY';
export const APPOINTMENT = 'APPOINTMENT';

export const CONFIRM_DOUBLE_REGISTRATION =
  'This patient has already registered a visit in the registry. Do you still want to proceed?';

export const CANCELLED_VISIT =
  'This patient cancelled the visit.';



// Inventory Invalid Msgs
export const SEPARATE_BATCH_AVAILABLE =
  'Items are available in different batches. Please issue item separately.';
// export const SEPARATE_BATCH_AVAILABLE = 'Input quantity exceeds batch stock. Please add separate batch.';

export const INSUFFICIENT_STOCK = 'Stock may not be sufficient.';
export const UNAVAILABLE_INVENTORY =
  'This item is not available in the inventory.';

export const CHAS_BALANCE_UNAVAILABLE =
  "CHAS balance can't be retrieved. Please check with the portal.";
export const BALANCE_UNAVAILABLE =
  'Balance is unavailable. Please check with the portal.';

export const PCN_CONDITIONS = [
  { name: 'Pre-Diabetes', selected: false, id: 1 },
  { name: 'Diabetes', selected: false, id: 2 },
  { name: 'Hypertension', selected: false, id: 3 },
  { name: 'Lipid Disorders', selected: false, id: 4 },
  { name: 'Stroke', selected: false, id: 5 },
  { name: 'Dementia', selected: false, id: 2 },
  { name: 'Asthma', selected: false, id: 6 },
  { name: 'COPD', selected: false, id: 7 },
  { name: 'Osteoarthritis', selected: false, id: 8 },
  { name: 'Benign Prostatic Hyperplasia', selected: false, id: 9 },
  { name: 'Nephritis / Nephrosis', selected: false, id: 10 },
  { name: "Parkinson's Disease", selected: false, id: 11 },
  { name: 'Epilepsy', selected: false, id: 12 },
  { name: 'Osteoporosis', selected: false, id: 13 },
  { name: 'Psoriasis', selected: false, id: 14 },
  { name: 'Rheumatoid arthritis', selected: false, id: 15 },
];

export const PCN_CHAS_STATUS = [
  'CHAS Blue',
  'CHAS Orange',
  'CHAS Green',
  'MG',
  'PA',
  'PG',
  'Non-CHAS',
];

export const DOCTOR_BLOCK_TYPE = [
  { name: 'Leave', value: 'AL' },
  { name: 'Blocked Time', value: 'BL' },
];

export const PCN_MEDISAVE_USAGE = [
  {
    value: true,
    label: 'YES',
  },
  { value: false, label: 'NO' },
];

export const PCN_REFERENCE_SOURCE = [
  'Self',
  'Polyclinic',
  'Private Clinics/Hospitals',
  'PHIs/SOCs',
  'FMCs',
];

export const DATA_SAVED_SUCCESSFULLY = 'Data Saved Successfully.';

export const COVERAGE_MODE = {
  ADD_TO_REGISTRY: 'ADD_TO_REGISTRY',
  ATTACH_MEDICAL_COVERAGE: 'ATTACH_MEDICAL_COVERAGE',
  DISPLAY_MEDICAL_COVERAGE: 'DISPLAY_MEDICAL_COVERAGE',
  REGISTER_PATIENT_ONLY: 'REGISTER_PATIENT_ONLY',
};

export const colors: any = {
  red: {
    primary: '#EFC7C2',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#FFE5D4',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#BFD3C1',
    secondary: '#FDF1BA',
  },
  green: {
    primary: '#E0D3DE',
    secondary: '#FDF1BA',
  },
  black: {
    primary: '#FFFFFF',
    secondary: '#000000',
  },
};

export const doctorColorCode: any = [
  '#E3B23C',
  '#8FC93A',
  '#1E91D6',
  '#A06CD5',
  '#FFA69E',
  '#FFEECF',
  '#4B3F72',
  '#417B5A',
  '#D0CEBA',
  '#A06CD5',
  '#E9D2C0',
  '#A4B494',
  '#90AA86',
  '#417B5A',
  '#4A2545',
  '#8B575C',
  '#C98986',
  '#F6BDD1',
  '#87D68D',
  '#93B48B',
  '#8491A3',
  '#9FA4A9',
  '#847E89',
  '#AFBFC0',
  '#273C2C',
  '#626868',
  '#939196',
  '#9FA4A9',
  '#D3C1D2',
  '#FFE2FE',
  '#EAD2AC',
  '#DF928E',
  '#C58882',
  '#D1DEDE',
  '#BEBBBB',
  '#DB8A74',
];

export const locumColorCode = '#3949ab';
export const noPreferredDoctorColorCode = '#4D4861';

export const EMERGENCY_CONTACT_RELATIONSHIPS = [
  { value: 'SPOUSE', label: 'SPOUSE' },
  { value: 'FATHER', label: 'FATHER' },
  { value: 'MOTHER', label: 'MOTHER' },
  { value: 'PARENT', label: 'PARENT' },
  { value: 'CHILDREN', label: 'CHILDREN' },
  { value: 'IN_LAWS', label: 'IN LAWS' },
  { value: 'GRANDPARENT', label: 'GRANDPARENT' },
  { value: 'FRIEND', label: 'FRIEND' },
  { value: 'RELATIVE', label: 'RELATIVE' },
  { value: 'OTHER', label: 'OTHER' },
];

export const TOASTR_TIMEOUT = 3000;

export const NPHS_SUPERVISOR_ROLE = "ROLE_NPHS_SUPERVISOR";// add new after two weeks
export const APPOINTMENT_SUPERVISOR_ROLE = "ROLE_APPOINTMENT_SUPERVISOR";// CAN EDIT PATIENT INFO, and add new from today, 
export const EXTERNAL_USER_ROLE = "ROLE_NPHS_USER";// after two weeks in add new


export const CLINICS_ARRAY = [{

  "id": "5b55aabd0550de0021097bd4",
  "name": "HEALTHWAY MEDICAL @ HOUGANG MALL-HOC",
  "groupName": "",
  "address": {
    "address": "90 HOUGANG AVE 10, HOUGANG MALL #04-06",
    "postalCode": "538766"
  },
  "contactNumber": "63437620",
  "clinicCode": "HOC",

  "faxNumber": "",
  "attendingDoctorId": [],
  "clinicStaffUsernames": [],
  "companyRegistrationNumber": "",
  "gstRegistrationNumber": "",
  "heCode": "",
  "clinicLogo": "",
  "pcnRegistered": false,
  "clinicFeatures": [],
  "status": ""
},
{
  "id": "5b55aabd0550de0021097bd7",
  "name": "HEALTHWAY MEDICAL @ JURONG WEST- JPC",
  "groupName": "",
  "address": {
    "address": "BLK 690 JURONG WEST CENTRAL 1\n#01-193",
    "postalCode": "640690"
  },
  "contactNumber": "67921812",
  "clinicCode": "JP",
  "faxNumber": "",
  "attendingDoctorId": [],
  "clinicStaffUsernames": [],
  "companyRegistrationNumber": "",
  "gstRegistrationNumber": "",
  "heCode": "",
  "clinicLogo": "",
  "pcnRegistered": false,
  "clinicFeatures": [],
  "status": ""
},
{
  "id": "5b55aabd0550de0021097be6",
  "name": "HEALTHWAY MEDICAL @ TAMPINES - TCC",
  "groupName": "",
  "address": {
    "address": "BLK 503 TAMPINES CENTRAL 1\n#01-311",
    "postalCode": "520503"
  },
  "contactNumber": "67890383",
  "clinicCode": "TCC",

  "faxNumber": "",
  "attendingDoctorId": [],
  "clinicStaffUsernames": [],
  "companyRegistrationNumber": "",
  "gstRegistrationNumber": "",
  "heCode": "",
  "clinicLogo": "",
  "pcnRegistered": false,
  "clinicFeatures": [],
  "status": ""
},
{
  "id": "5b55aabd0550de0021097be8",
  "name": "HEALTHWAY MEDICAL @ TOA PAYOH - TPC",
  "groupName": "",
  "address": {
    "address": "BLK 177 TOA PAYOH CENTRAL\n#01-130",
    "postalCode": "310177"
  },
  "contactNumber": "62553773",
  "clinicCode": "TPC",

  "faxNumber": "",
  "attendingDoctorId": [],
  "clinicStaffUsernames": [],
  "companyRegistrationNumber": "",
  "gstRegistrationNumber": "",
  "heCode": "",
  "clinicLogo": "",
  "pcnRegistered": false,
  "clinicFeatures": [],
  "status": ""
},
{
  "id": "5b55aabd0550de0021097bed",
  "name": "HEALTHWAY MEDICAL @ YISHUN - YIS",
  "groupName": "",
  "address": {
    "address": "BLK 748 YISHUN STREET 72\n#01-230",
    "postalCode": "760748"
  },
  "contactNumber": "68537101",
  "clinicCode": "YIS",

  "faxNumber": "",
  "attendingDoctorId": [],
  "clinicStaffUsernames": [],
  "companyRegistrationNumber": "",
  "gstRegistrationNumber": "",
  "heCode": "",
  "clinicLogo": "",
  "pcnRegistered": false,
  "clinicFeatures": [],
  "status": ""
},
{

  "id": "64ab817a4f15ab1f330dbb86",
  "name": "HEALTHWAY MEDICAL @ JALAN MEMBINA-TBC",
  "groupName": "",
  "address": {
    "address": "18 JALAN MEMBINA, #2-08",
    "postalCode": "164018"
  },
  "contactNumber": "60167258",
  "clinicCode": "TBJM",

  "faxNumber": "",
  "attendingDoctorId": [],
  "clinicStaffUsernames": [],
  "companyRegistrationNumber": "",
  "gstRegistrationNumber": "",
  "heCode": "",
  "clinicLogo": "",
  "pcnRegistered": false,
  "clinicFeatures": [],
  "status": ""
}];


export const SEARCH_FILTERS_ARRAY = [
  "Name",
  "Contact Number",
  "SerialHi"
];
export const IDTYPES_ARRAY = [
  { value: 'NRIC_PINK', label: 'NRIC (Pink)' },
  { value: 'NRIC_BLUE', label: 'NRIC (Blue)' },
  { value: 'OTHER', label: 'Other' },
];
export const PHONETYPE_ARRAY = [
  "Mobile", "Home"
];

export const GENDER_ARRAY = [
  "Male", "Female"
];
