import { Router } from '@angular/router';
import { AuthServiceService } from '../services/auth/auth-service.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/auth/auth-service.service";
import * as i2 from "@angular/router";
var AppointmentAuthGuard = /** @class */ (function () {
    function AppointmentAuthGuard(_authService, _router) {
        this._authService = _authService;
        this._router = _router;
    }
    AppointmentAuthGuard.prototype.canActivate = function (activatedRouteSnapshot) {
        var queryParams = activatedRouteSnapshot.queryParams;
        if (queryParams.serialHi) {
            return true;
        }
        else {
            this._router.navigate(['/login']);
            return false;
        }
    };
    AppointmentAuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppointmentAuthGuard_Factory() { return new AppointmentAuthGuard(i0.ɵɵinject(i1.AuthServiceService), i0.ɵɵinject(i2.Router)); }, token: AppointmentAuthGuard, providedIn: "root" });
    return AppointmentAuthGuard;
}());
export { AppointmentAuthGuard };
