import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { baseUrl, loginUrl } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "ngx-toastr";
var AuthServiceService = /** @class */ (function () {
    function AuthServiceService(http, router, toastr) {
        this.http = http;
        this.router = router;
        this.toastr = toastr;
        this.twoFASub = new Subject();
        this.API_URL = baseUrl;
        this.API_LOGIN_URL = loginUrl;
    }
    AuthServiceService.prototype.setTwoFAStatus = function (status) {
        this.twoFASub.next(status);
    };
    AuthServiceService.prototype.getTwoFAStatus = function () {
        return this.twoFASub.asObservable();
    };
    AuthServiceService.prototype.login = function (user) {
        return this.http.post(this.API_LOGIN_URL + "/aacore/login", JSON.stringify(user), {
            observe: 'response',
        });
    };
    AuthServiceService.prototype.getUser = function () {
        return this.http.post(this.API_LOGIN_URL + "/aacore/user", '');
    };
    AuthServiceService.prototype.updatePassword = function (oldPassword, newPassword) {
        var oldPasswordValue = oldPassword;
        var newPasswordValue = newPassword;
        return this.http.post(this.API_LOGIN_URL + "/aacore/user/change/password/" + oldPasswordValue + "/" + newPasswordValue, {});
    };
    AuthServiceService.prototype.loggedIn = function () {
        return !!localStorage.getItem('access_token');
    };
    AuthServiceService.prototype.loginWithTwoFA = function (data) {
        return this.http.post(this.API_LOGIN_URL + "/aacore/user/2fa", data);
    };
    AuthServiceService.prototype.getUserDetails = function () {
        var _this = this;
        this.getUser().subscribe(function (resp) {
            var payload = resp.payload;
            localStorage.setItem('firstName', payload.firstName);
            _this.router.navigate(['/dashboard']);
        }, function (err) {
            _this.toastr.error(err.error.message ? err.error.message : '');
        });
    };
    AuthServiceService.prototype.getToken = function () {
        return localStorage.getItem("access_token");
    };
    AuthServiceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthServiceService_Factory() { return new AuthServiceService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.ToastrService)); }, token: AuthServiceService, providedIn: "root" });
    return AuthServiceService;
}());
export { AuthServiceService };
